<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Planner List</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'create-planner-details' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">

         New Planner
          </router-link>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                  >Field</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="filter.field"
                  class="form-select"
                >
                <option value="first_name">First Name</option>
                <option value="last_name">Last Name</option>
                <option value="short_name">Short Name</option>
                <option value="email">Email</option>
                </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-fullo"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2">Value</label>
                <input
                  id="tabulator-html-filter-value"
                  v-model="filter.value"
                  type="text"
                  class="form-control sm:w-40"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full"
                  @click="onFilter"
                >
                  Go
                </button>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-reset"
                  type="button"
                  class="btn btn-secondary w-full"
                  @click="onResetFilter"
                >
                  Reset
                </button>
            </div>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref,onMounted, reactive } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'first_name',
      type: 'like',
      value: ''
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/master/get_planner_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        headerSort: false,
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'First Name',
            responsive: 0,
            field: 'first_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Last Name',
            responsive: 0,
            field: 'last_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'User Name',
            responsive: 0,
            field: 'user_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Short Name',
            responsive: 0,
            field: 'short_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Manager',
            responsive: 0,
            field: 'manager_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Email Id',
            responsive: 0,
            field: 'email',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Mobile No',
            responsive: 0,
            field: 'mobile_no',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Role',
            // minWidth: 100,
            field: 'user_role',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
             if(cell.getData().user_role == 5) {
              return 'Planner'
             } else if(cell.getData().user_role == 6) {
               return 'Checker'
             }
            }
          },
          {
            title: 'Limit',
            responsive: 0,
            field: 'process_limit',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
            if(cell.getData().process_limit != null) {
              return cell.getData().process_limit
            } else {
              return ''
            }
            }

          },
          {
            title: 'Status',
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex lg:justify-center ${
                cell.getData().active_status == 'Active' ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 'Active' ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: '',
            minWidth: 50,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
              cash(a).on('click', function() {
                //getDetails(cell.getData().id);
                router.push("/master/planner/update/" + window.btoa(cell.getData().user_id));
              })

              return a[0]
            }
          },

          {
            title: 'First Name',
            field: 'first_name',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'Last Name',
            field: 'last_name',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'User Name',
            field: 'user_name',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'Short Name',
            field: 'short_name',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'Manager',
            field: 'manager_name',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'Email Id',
            field: 'email',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'Mobile No',
            field: 'mobile_no',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'Role',
            field: 'user_role',
            visible: false,
            print: true,
            download: true,
            formatter(cell) {
             if(cell.getData().user_role == 5) {
              return 'Planner'
             } else if(cell.getData().user_role == 6) {
               return 'Checker'
             }
            }
          },
          {
            title: 'Limit',
            field: 'process_limit',
            visible: false,
            print: true,
            download: true,

          },
          {
            title: 'Status',
            field: 'active_status',
            visible: false,
            print: true,
            download: true
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    //Edit user
    const editUser = (id) => {
      console.log(id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'first_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      editUser
    }
  }
})
</script>
