<template>
<tr :class="{'text-theme-6' : packet.blocked == 1 }">
    <td class="text-center text-sm py-2">{{ index + 1 }}</td>
    <td class="text-center text-sm py-2">{{ packet.transaction_code }}</td>
    <td class="text-center text-sm py-2">{{ packet.process_short_code ? packet.process_short_code : '' }}</td>
    <td class="text-center text-sm py-2">{{ packet.transaction_type == 1 ? packet.issue_short_code : packet.manager_short_code }}</td>
    <td class="text-center text-sm py-2">{{ packet.transaction_type == 1 ? packet.manager_short_code : (packet.process_type == 1 || packet.process_type == 2 || packet.process_type == 5 || packet.process_type == 6 || packet.process_type == 7 || packet.process_type == 8 || packet.process_type == 9 ? (packet.external == 1 ? packet.jober_short_code :packet.employee_short_code) : (packet.process_type == 2 ? (packet.external == 1 ? packet.jober_short_code :packet.employee_short_code) : packet.planner_short_code)) }}</td>
    <td class="text-center text-sm py-2">{{ packet.issue_weight }}</td>
    <td class="text-center text-sm py-2"><input v-if="pagedata.editable" type="number" class="form-control w-16 text-center" v-model="changes.weight">
        <div v-else>{{ packet.receive_weight }}</div>
    </td>
    <td class="text-center text-sm py-2"><span>{{ packet.issue_date ? packet.issue_date : '-' }}</span><br><span>{{ packet.receive_date ? packet.receive_date : '-' }}</span></td>
    <td class="text-center text-sm py-2">
        <div v-if="packet.shape">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.shape" aria-label="Default select example">
                    <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.shape.shape_name }}
            </div>
        </div>
    </td>
    <td class="text-center text-sm py-2">
        <div v-if="packet.color">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.color" aria-label="Default select example">
                    <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.color.color_name }}
            </div>
        </div>
    </td>
    <td class="text-center text-sm py-2">
        <div v-if="packet.purity">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.purity" aria-label="Default select example">
                    <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.purity.purity_name }}
            </div>
        </div>
    </td>
    <td class="text-center text-sm py-2">
        <div v-if="packet.cut">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.cut" aria-label="Default select example">
                    <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.cut.cut_name }}
            </div>
        </div>
    </td>
    <td class="text-center text-sm py-2">
        <div v-if="packet.polish">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.polish" aria-label="Default select example">
                    <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.polish.polish_name }}
            </div>
        </div>
    </td>
    <td class="text-center text-sm py-2">
        <div v-if="packet.symmetry">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.symmetry" aria-label="Default select example">
                    <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.symmetry.symmetry_name }}
            </div>
        </div>
    </td>
    <!-- <td class="text-center text-sm py-2">
        <div v-if="packet.fluerocent">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.fluerocent" aria-label="Default select example">
                    <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.fluerocent_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.fluerocent.fluerocent_name }}
            </div>
        </div>
    </td> -->
    <!-- <td class="text-center text-sm py-2">
        <div v-if="packet.tension">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.tension" aria-label="Default select example">
                    <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.tension_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.tension.tension_name }}
            </div>
        </div>
    </td> -->
    <!-- <td class="text-center text-sm py-2">
        <div v-if="packet.stone">
            <div class="input-group mt-2 sm:mt-0" v-if="pagedata.editable">
                <select class="form-select w-14" v-model="changes.stone" disabled aria-label="Default select example">
                    <option v-for="col in diamond.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                </select>
            </div>
            <div v-else>
                {{ packet.stone.stone_name }}
            </div>
        </div>
    </td> -->
    <td class="text-center text-sm py-2">
        <div class="flex justify-center items-center" v-if="packet.status == 1 && packet.transaction_type == 2 && packet.manager_id == user_details.account_id">
            <!-- <button title="Edit" class="btn btn-success mr-1" :disabled="packet.blocked != null || packet.discuss != null" @click="$emit('getDiamondDetail',packet)" v-if="packetDet.status == 2">
                R
            </button> -->
            <button class="btn btn-danger mr-1" :disabled="packet.blocked != null || packet.discuss != null" @click="cancelProcess(packet.packet_id)" v-if="packetDet.status == 2">
                X
            </button>
        </div>
        <div v-else-if="packet.status == 3 || packet.transaction_status == 3">
            <div class="text-theme-6">
                <ShieldOffIcon class=" h-5 w-5" />
            </div>
        </div>
        <div v-else-if="(packet.transaction_type == 1 || packet.transaction_type == 4) && packet.issue_by == user_details.id">
            <div class="text-theme-9">
                <ArrowUpRightIcon class=" h-6 w-6" />
            </div>
        </div>
        <div v-else-if="(packet.transaction_type == 1 || packet.transaction_type == 4) && packet.manager_id == user_details.account_id">
            <div class="text-theme-6">
                <ArrowDownLeftIcon class=" h-6 w-6" />
            </div>
        </div>
        <div v-else-if="(packet.transaction_type == 1 || packet.transaction_type == 4) && (packet.issue_by != user_details.id && packet.manager_id != user_details.account_id)">
            <div class="text-theme-1">
                <ExternalLinkIcon class=" h-5 w-5" />
            </div>
        </div>
        <!-- CheckIcon -->
        <div v-else-if="(packet.transaction_type == 2 && packet.status == 2)">
            <div class="text-theme-9">
                <CheckIcon class=" h-5 w-5" />
            </div>
            <!-- <button
          class="btn btn-primary mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == false" :disabled="packetDet.blocked == 1 || packet.status == 3"
          >
          <ExternalLinkIcon class="h-4 w-4" />
        </button>
        <div v-else>
          <button class="btn bg-primary-9 text-white mr-1 p-2" :disabled="packet.blocked == 1 || packet.status == 3"><SaveIcon class="w-4 h-4" @click="updateParameters"/></button>
          <button class="btn btn-danger mr-1 p-2" @click="pagedata.editable = !pagedata.editable" v-if="pagedata.editable == true" :disabled="packet.blocked == 1 || packet.status == 3"><XIcon class="h-4 w-4" /></button>
        </div> -->
        </div>
        <div v-else-if="(packet.transaction_type == 2 && packet.status == 1)">
            <div class="text-theme-1">
                <ShieldIcon class=" h-5 w-5" />
            </div>
        </div>
    </td>
</tr>
</template>

<script>
/* eslint-disable */
import {
    computed,
    defineComponent,
    inject,
    reactive,
    watch
} from 'vue'
import Toastify from 'toastify-js'
import axios from 'axios'
import {
    BASE_URL
} from '@/constants/config'
import store from '@/store'
export default defineComponent({
    props: ['packet', 'index', 'diamond', 'packetDet'],
    emits: ['getDiamondDetail', 'updatParameters', 'getData', 'cancelProcess'],
    setup(props, {
        emit
    }) {
        const changes = reactive({
            id: props.packet.id,
            weight: props.packet.receive_weight,
            color: props.packet.color ? props.packet.color.id : '',
            cut: props.packet.cut ? props.packet.cut.id : '',
            purity: props.packet.purity ? props.packet.purity.id : '',
            polish: props.packet.polish ? props.packet.polish.id : '',
            shape: props.packet.shape ? props.packet.shape.id : '',
            symmetry: props.packet.symmetry ? props.packet.symmetry.id : '',
            fluerocent: props.packet.fluerocent ? props.packet.fluerocent.id : '',
            stone: props.packet.stone ? props.packet.stone.id : '',
            tension: props.packet.tension ? props.packet.tension.id : '',
        })
        const swal = inject('$swal')
        const user_details = computed(() => store.state.user.currentUser)
        const issue = computed(() => props.packet.issue_weight)
        const receive = computed(() => props.packet.receive_weight)
        const pagedata = reactive({
            editable: props.packet.issue_weight < props.packet.receive_weight ? true : false,
            error: '',
            success: ''
        })

        function updateParameters() {
            if (props.packet.type == 'rough') {
                if (parseFloat(changes.weight) != parseFloat(props.packet.receive_weight)) {
                    if (parseFloat(props.packet.issue_weight) < parseFloat(changes.weight)) {
                        swal("Warning!", "Receive Weight Must Be Less then Issue Weight!", "warning");
                        return
                    }
                    UpdateWeight()
                    return
                } else {
                    swal("Warning!", "Please Change Parameters !", "warning");
                    return
                }
            } else {
                if (changes.color == props.packet.color.id && changes.cut == props.packet.cut.id && changes.purity == props.packet.purity.id && changes.polish == props.packet.polish.id &&
                    changes.shape == props.packet.shape.id && changes.symmetry == props.packet.symmetry.id && changes.fluerocent == props.packet.fluerocent.id && changes.stone == props.packet.stone.id &&
                    changes.tension == props.packet.tension.id) {
                    if (parseFloat(changes.weight) != parseFloat(props.packet.receive_weight)) {
                        if (parseFloat(props.packet.issue_weight) < parseFloat(changes.weight)) {
                            swal("Warning!", "Receive Weight Must Be Less then Issue Weight!", "warning");
                            return
                        } else if (parseFloat(props.packetDet.cr_polish_weight) < parseFloat(changes.weight)) {
                            UpdateWeight()
                            return
                        }
                    } else {
                        swal("Warning!", "Please Change Parameters !", "warning");
                        return
                    }
                } else {
                    emit('updatParameters', changes)
                    pagedata.editable = false
                    return
                }
            }
        }

        function UpdateWeight() {
            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Update Weight ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let promise = axios({
                        url: 'api/request/update_mdl_process_weight',
                        method: "post",
                        data: {
                            weight: changes.weight,
                            request_id: changes.id
                        },
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            pagedata.editable = false
                            swal("Success!", "Transaction Weight is Updated !", "success");
                            emit('getData')
                        })
                        .catch(_error => {
                            swal("Warning!", "Somthing went Wrong !", "warning");
                        });
                }
            })
        }

        const cancelProcess = (id) => {

            swal({
                title: 'Are you sure?',
                text: 'Do you really want to Cancel Packet? This process cannot be undone.',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm & Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    const params = {
                        id: window.btoa(id)
                    }

                    let promise = axios({
                        url: "api/process/cancel_process",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.status == "error") {
                                swal("Error!", data.message, "error");
                            } else {
                                emit('getData')
                                swal("Success!", data.message, "success");
                            }
                        })
                        .catch(_error => {
                            swal("Error!", 'Something went wrong', "error");
                            return [];
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then(result => {
                if (result.value) {
                    //swal("Success!", "User Password Updated.", "success");
                }
            });
        }
        watch(() => issue.value, (newValue, OldValue) => {
            if (receive.value > issue.value) {
                pagedata.editable = true
            }
        })
        return {
            changes,
            pagedata,
            updateParameters,
            user_details,
            cancelProcess
        }
    }
})
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
