<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Receive Lot Recut</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-12 xl:col-span-4">

                            <div>
                                <label for="update-profile-form-8" class="form-label">Scan Lot No</label>
                                <div class="input-group mt-2">

                                    <input id="update-profile-form-6" type="text" tabindex="1" placeholder="Scan Barcode" v-model="scan_lot_no" :class="{'form-control':true,'loading':scan_load}" :disabled="scan_load" autocomplete="off" @change="scanAndAddPack" />

                                    <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                                        <SearchIcon class="w-5 h-5 mr-2" /> Search
                                    </button>
                                    <button type="button" class="btn btn-primary-outline mr-auto" @click="clearSearch('')" style="border-radius: 0!important">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>

    <div class="grid grid-cols-12 mt-5" v-if="packet_item.main_transaction_status == 1 && packet_item.part_ready == null && (user_details.account_id == packet_item.employee_id) && (user_details.user_role == packet_item.employee_role) && packet_item.process_type == 5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-between p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
                    <!-- <h2 class="font-medium text-base mr-auto"></h2> -->
                    <div class="text-right">

                        <button class="btn btn-primary" @click="set_part_details">Add Sub Part</button>
                    </div>
                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-12 md:col-span-4">

                            <div>
                                <label for="update-profile-form-6" class="form-label">Packet No :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.number}}</label>
                            </div>
                            <div>
                                <label for="update-profile-form-6" class="form-label">Issue Lot weight :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.cr_weight}} Ct</label>
                            </div>

                            <div>
                                <label for="update-profile-form-6" class="form-label">Issue To :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.employee_first_name + ' '+ packet_item.employee_last_name}}</label>
                            </div>
                            <div>
                                <label for="update-profile-form-6" class="form-label">Issue At :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.created_at}}</label>
                            </div>

                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <div>
                                <label for="update-profile-form-6" class="form-label">Return Weight :&nbsp;</label>
                                <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.weight" :class="{'form-control':true,'loading':scan_load}" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateGhat" readonly />

                            </div>

                            <div class="mt-2">

                                <label for="update-profile-form-6" class="form-label">Process Ghat :&nbsp;</label>
                                <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.ghat_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                            </div>
                            <div class="mt-2">

                                <label for="update-profile-form-6" class="form-label">Process Assortment :&nbsp;</label>
                                <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.assortment_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->

        </div>

    </div>
    <div class="grid grid-cols-12 mt-5" v-if="sub_packets.length > 0 && packet_item.part_ready == null">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Sub Parts</h2>
                    <div class="text-right">
                        <!-- <button v-print="'#print-all-barcods'" class="btn btn-primary mr-2">Print All</button> -->
                    </div>
                </div>

                <div class="p-1 pb-10">
                    <div class="col-span-12">
                        <div class="border">
                            <div style="background-color:#fff;">
                                <table class="mt-3 w-full">
                                    <thead>
                                        <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                                            <th class="py-2 text-center border">Pkt No</th>
                                            <th class="py-2 text-center border">Weight</th>
                                            <!-- <th class="py-2 text-center border">Exp. Wt</th> -->
                                            <!-- <th class="py-2 text-center border">Sp</th>
                        <th class="py-2 text-center border">Cl</th>
                        <th class="py-2 text-center border">Prt</th>
                        <th class="py-2 text-center border">Ct</th>
                        <th class="py-2 text-center border">Pl</th>
                        <th class="py-2 text-center border">Sym</th> -->
                                            <th class="py-2 text-center border">Stn</th>
                                            <!-- <th class="py-2 text-center border">Fluc</th>
                        <th class="py-2 text-center border">Tn</th> -->
                                            <th class="py-2 text-center border">Print</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(packet, index) in sub_packets" :key="index" class="intro-x">
                                            <td class="py-2 font-medium text-center border">{{ packet.number }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.weight }}</td>
                                            <!-- <td class="py-2 font-medium text-center border">{{ packet.cr_polish_weight }}</td> -->
                                            <!-- <td class="py-2 font-medium text-center border">{{ packet.shape }}</td>
                        <td class="py-2 font-medium text-center border">{{ packet.color }}</td>
                        <td class="py-2 font-medium text-center border">{{ packet.purity }}</td>
                        <td class="py-2 font-medium text-center border">{{ packet.cut }}</td>
                        <td class="py-2 font-medium text-center border">{{ packet.polish }}</td>
                        <td class="py-2 font-medium text-center border">{{ packet.symmetry }}</td> -->
                                            <td class="py-2 font-medium text-center border">{{ packet.stone }}</td>
                                            <!-- <td class="py-2 font-medium text-center border">{{ packet.fluerocent }}</td>
                        <td class="py-2 font-medium text-center border">{{ packet.tension }}</td> -->
                                            <td class="py-2 font-medium text-center border">
                                                <div class="flex justify-center items-center">
                                                    <button class="text-theme-1 mr-3" v-print="'#' + packet.number">
                                                        <PrinterIcon class="w-5 h-5" />
                                                    </button>
                                                    <div class="p-0 m-0 hidden">
                                                        <div :id="packet.number" class="absolute top-0 left-0 p-0 m-0">
                                                            <table class="p-0 m-0 w-full text-black" border="collapse">
                                                                <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ packet.polish_weight }}</th>
                                                                </tr>
                                                                <tr class="text-center m-0 p-0">
                                                                    <th colspan="9" class="text-center">
                                                                        <VueBarcode :value="packet.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                                                                    </th>
                                                                </tr>
                                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                    <th class="border m-auto border-black">SHP</th>
                                                                    <th class="border m-auto border-black">CL</th>
                                                                    <th class="border m-auto border-black">PRT</th>
                                                                    <th class="border m-auto border-black">CT</th>
                                                                    <th class="border m-auto border-black">PL</th>
                                                                    <th class="border m-auto border-black">SYM</th>
                                                                    <th class="border m-auto border-black">Fl</th>
                                                                    <th class="border m-auto border-black">TN</th>
                                                                    <th class="border m-auto border-black">STN</th>
                                                                </tr>
                                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                    <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.color }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.stone }}</th>
                                                                </tr>
                                                            </table>
                                                            <p class="p-0 mt-1 text-black w-full"
                                    style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
                                      class="text-left">{{ packet.created_at }}</span> / <span class="text-right">Kodllin
                                      TECHONOLOGY</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="hidden">
                                    <div id="print-all-barcods" class="absolute left-0 top-0 p-0 m-0">

                                        <div style="page-break-before: always;" :class="{'mt-5': index != 0 || (index == 1 && packet_details[0]['ls_done_id'] == null)}" v-for="(packet,index) in packet_details" :key="index">
                                            <table class="p-0 w-full text-black" border="collapse" v-if="(index != 0) || (index == 0 && packet.ls_done_id != null)">
                                                <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ packet.polish_weight }}</th>
                                                </tr>
                                                <tr class="text-center">
                                                    <th colspan="9" class="text-center">
                                                        <VueBarcode :value="packet.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                                                    </th>
                                                </tr>
                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0">
                                                    <th class="border m-auto border-black">SHP</th>
                                                    <th class="border m-auto border-black">CL</th>
                                                    <th class="border m-auto border-black">PRT</th>
                                                    <th class="border m-auto border-black">CT</th>
                                                    <th class="border m-auto border-black">PL</th>
                                                    <th class="border m-auto border-black">SYM</th>
                                                    <th class="border m-auto border-black">Fl</th>
                                                    <th class="border m-auto border-black">TN</th>
                                                    <th class="border m-auto border-black">STN</th>
                                                </tr>
                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0">
                                                    <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                    <th class="border m-auto border-black">{{ packet.color }}</th>
                                                    <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                    <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                    <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                    <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                    <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                    <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                    <th class="border m-auto border-black">{{ packet.stone }}</th>
                                                </tr>
                                            </table>
                                            <p class="p-0 mt-1 text-black w-full" v-if="index != 0"
                                    style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
                                      class="text-left">{{ packet.created_at }}</span> / <span class="text-right">Kodllin
                                      TECHONOLOGY</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
    <div class="grid grid-cols-12 mt-5" v-if="packet_item.main_transaction_status == 1 && packet_item.part_ready == null && (user_details.account_id == packet_item.employee_id) && (user_details.user_role == packet_item.employee_role) && packet_item.process_type == 5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Packet Plan Details <span v-if="costing_value"> ( {{ costing_value }} $ )</span>
                        <span>
                            <span v-if="costing_value">
                                [ <span v-if="menual_total.value - costing_value > 0" class="text-theme-9">
                                    <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (menual_total.value - costing_value) /costing_value).toFixed(2) }} % )
                                </span>
                                <span v-else class="text-theme-6">
                                    <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (costing_value - menual_total.value) /costing_value).toFixed(2) }} % )
                                </span> ]
                            </span>
                            <span>
                                [ <span v-if="menual_total.value - costing_value_manager > 0" class="text-theme-9">
                                    <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (menual_total.value - costing_value_manager) /costing_value_manager).toFixed(2) }} % )
                                </span>
                                <span v-else class="text-theme-6">
                                    <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (costing_value_manager - menual_total.value) /costing_value_manager).toFixed(2) }} % )
                                </span> ]
                            </span>
                        </span></h2>
                </div>

                <div class="p-1 pb-10">
                    <div class="col-span-12">
                        <div class="border">
                            <div style="background-color:#fff;">
                                <table class="w-full border">
                                    <thead>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">MK Wt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr class="border-b bg-gray-100 dark:bg-dark-1">
                        <td colspan="3">LS Weight</td>
                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input
                          class="show-code form-check-switch" @change="getPlanningTotal"
                          type="checkbox" v-model="menual_total.is_ls_weight"
                        /></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input v-if="menual_total.is_ls_weight" type="text" class="form-control w-16 text-center" @change="getPlanningTotal" v-model="menual_total.rough_weight"></td>
                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                        <td></td>
                      </tr> -->
                                        <tr v-for="(item,index) in packet_details" :key="index">
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span v-if="menual_total.is_ls_weight == false">{{ index + 1 }}</span> <span v-if="menual_total.is_ls_weight"><input type="checkbox" v-model="item.ls_check" @change="getPlanningTotal"></span></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.shape" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.color" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="color.id" v-for="color in diamond.colors" :key="color.id">{{ color.color_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.purity" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="purity.id" v-for="purity in diamond.purities" :key="purity.id">{{ purity.purity_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.cut" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="cut.id" v-for="cut in diamond.cuts" :key="cut.id">{{ cut.cut_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.polish" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="polish.id" v-for="polish in diamond.polish" :key="polish.id">{{ polish.polish_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.symmetry" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="symmetry.id" v-for="symmetry in diamond.symmetry" :key="symmetry.id">{{ symmetry.symmetry_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.fluerocent" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="fluerocent.id" v-for="fluerocent in diamond.fluerocents" :key="fluerocent.id">{{ fluerocent.fluerocent_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tension" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="tension.id" v-for="tension in diamond.tensions" :key="tension.id">{{ tension.tension_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.stone" @change="loadPlanningValues(item)" aria-label="Default select example" disabled>
                                                    <option :value="stone.id" v-for="stone in diamond.stones" :key="stone.id">{{ stone.stone_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.ratio" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tabl" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.height" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.r_rate"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.value"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.discount"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="getPlanningTotal" v-model="item.weight"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="loadPlanningValues(item)" v-model="item.polish_weight"></td>
                                            <td>
                                                <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="removeField(item.id)">
                                                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                                </button>
                                            </td>
                                        </tr>
                                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.value }}</td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.weight }}</td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.polish_weight }}</td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                            <td colspan="3">Assortment Weight</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 text-center" @input="getPlanningTotal" v-model="menual_total.assortment_weight"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                            <td colspan="3">Ghat Weight</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.ghat_weight }}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                    <div class="flex justify-between mt-4">
                        <button type="button" class="btn btn-primary mt-3" @click="addNewPart">
                            Add New Part
                        </button>
                        <button type="button" class="btn btn-primary mt-3" v-if="enable_return" @click="submitReturnRough">
                            Take Return
                        </button>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
    <div class="grid grid-cols-12 mt-5" v-else>
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Packet Last Plan Details</h2>
                    <div class="text-right">
                        <button v-print="'#print-all-barcods'" class="btn btn-primary mr-2">Print All</button>
                        <button class="btn btn-primary" @click="set_part_details" v-if="packet_item">Add Sub Part</button>
                    </div>
                </div>

                <div class="p-1 pb-10">
                    <div class="col-span-12">
                        <div class="border">
                            <div style="background-color:#fff;">
                                <table class="mt-3 w-full">
                                    <thead>
                                        <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                                            <th class="py-2 text-center border">Pkt No</th>
                                            <th class="py-2 text-center border">Weight</th>
                                            <th class="py-2 text-center border">Exp. Wt</th>
                                            <th class="py-2 text-center border">Sp</th>
                                            <th class="py-2 text-center border">Cl</th>
                                            <th class="py-2 text-center border">Prt</th>
                                            <th class="py-2 text-center border">Ct</th>
                                            <th class="py-2 text-center border">Pl</th>
                                            <th class="py-2 text-center border">Sym</th>
                                            <th class="py-2 text-center border">Stn</th>
                                            <th class="py-2 text-center border">Fluc</th>
                                            <th class="py-2 text-center border">Tn</th>
                                            <th class="py-2 text-center border w-28">Print</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(packet, index) in packet_details" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                                            <td class="py-2 font-medium text-center border">{{ packet.number }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.weight }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.polish_weight }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.shape }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.color }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.purity }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.cut }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.polish }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.symmetry }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.stone }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.fluerocent }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.tension }}</td>
                                            <td class="py-2 font-medium text-center border">{{ packet.print_status }}</td>
                                            <td class="py-2 font-medium text-center border">
                                              <div class="flex justify-between items-center">
                                                  <input
                                                class="show-code form-check-switch mr-0 ml-3 p-0"
                                                type="checkbox" v-model="packet.print_status"
                                              />
                                                    <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == true" @click="update_print_status(packet)">
                                                        <PrinterIcon class="w-5 h-5" />
                                                    </button>
                                                    <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == false" disabled @click="update_print_status(packet)">
                                                        <PrinterIcon class="w-5 h-5" />
                                                    </button>
                                                    <div class="p-0 m-0 hidden">
                                                        <div :id="packet.number" class="absolute top-0 left-0 p-0 m-0">
                                                            <table class="p-0 m-0 w-full text-black" border="collapse">
                                                                <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ packet.polish_weight }}</th>
                                                                </tr>
                                                                <tr class="text-center m-0 p-0">
                                                                    <th colspan="9" class="text-center">
                                                                        <VueBarcode :value="packet.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                                                                    </th>
                                                                </tr>
                                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                    <th class="border m-auto border-black">SHP</th>
                                                                    <th class="border m-auto border-black">CL</th>
                                                                    <th class="border m-auto border-black">PRT</th>
                                                                    <th class="border m-auto border-black">CT</th>
                                                                    <th class="border m-auto border-black">PL</th>
                                                                    <th class="border m-auto border-black">SYM</th>
                                                                    <th class="border m-auto border-black">Fl</th>
                                                                    <th class="border m-auto border-black">TN</th>
                                                                    <th class="border m-auto border-black">STN</th>
                                                                </tr>
                                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                    <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.color }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.stone }}</th>
                                                                </tr>
                                                            </table>
                                                            <p class="p-0 mt-1 text-black w-full"
                                    style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
                                      class="text-left">{{ packet.created_at }}</span> / <span class="text-right">Kodllin
                                      TECHONOLOGY</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="hidden">
                                    <div id="print-all-barcods" class="absolute left-0 top-0 p-0 m-0">

                                        <div style="page-break-before: always;" :class="{'mt-5': index != 0 || (index == 1 && packet_details[0]['ls_done_id'] == null)}" v-for="(packet,index) in packet_details" :key="index">
                                            <table class="p-0 w-full text-black" border="collapse" v-if="(index != 0) || (index == 0 && packet.ls_done_id != null)">
                                                <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ packet.polish_weight }}</th>
                                                </tr>
                                                <tr class="text-center">
                                                    <th colspan="9" class="text-center">
                                                        <VueBarcode :value="packet.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                                                    </th>
                                                </tr>
                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0">
                                                    <th class="border m-auto border-black">SHP</th>
                                                    <th class="border m-auto border-black">CL</th>
                                                    <th class="border m-auto border-black">PRT</th>
                                                    <th class="border m-auto border-black">CT</th>
                                                    <th class="border m-auto border-black">PL</th>
                                                    <th class="border m-auto border-black">SYM</th>
                                                    <th class="border m-auto border-black">Fl</th>
                                                    <th class="border m-auto border-black">TN</th>
                                                    <th class="border m-auto border-black">STN</th>
                                                </tr>
                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0">
                                                    <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                    <th class="border m-auto border-black">{{ packet.color }}</th>
                                                    <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                    <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                    <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                    <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                    <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                    <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                    <th class="border m-auto border-black">{{ packet.stone }}</th>
                                                </tr>
                                            </table>
                                            <p class="p-0 mt-1 text-black w-full" v-if="index != 0"
                                    style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
                                      class="text-left">{{ packet.created_at }}</span> / <span class="text-right">Kodllin
                                      TECHONOLOGY</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
    <!-- END: Data List -->
    <div id="add-packet-part-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10">
                    <!-- <div id="vertical-form"> -->
                    <div class="flex flex-col justify-between mt-5">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex items-center">
                                <h2 class="intro-y text-lg font-medium mr-5">Add Sub Part</h2>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="intro-y col-span-12">
                                <!-- BEGIN: Input -->
                                <div class="">
                                    <div id="input" class="py-5">
                                        <div class="preview">
                                            <div>
                                                <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div class="">
                                                            <div id="input-group-4">Packet No</div>

                                                            <input id="pos-form-1" v-model="sub_part.packet_no" name="kapan" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Stock No</div>

                                                            <input id="pos-form-1" v-model="sub_part.vepari_no" name="stock" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Available Weight(Ct)</div>
                                                                <input type="text" class="form-control" name="piece" disabled v-model="sub_part.weight" @input="$h.preventInvalidInput" aria-describedby="input-group-3" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">New Part Weight (Ct)</div>
                                                            <input type="text" class="form-control" name="new weight" v-model="sub_part.new_weight" @input="$h.preventInvalidInput" aria-describedby="input-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="mt-3" v-if="reject_form.purchase_type == 2">
                                                  <div id="input-group-3" class="">Rejection Type</div>
                                                  <select class="form-select w-full" v-model="reject_form.rejection_type" disabled aria-label="Default select example">
                                                      <option value="1">Direct Rejection</option>
                                                      <option value="2" v-if="reject_form.purchase_type == 2">Net Rejection</option>
                                                  </select>
                                            </div> -->
                                            <div class="mt-3">
                                                <!-- <label class="form-label">Stone && Est Value</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>

                                                        <div class="">

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="mt-2 sm:mt-0">
                                                            <div class="flex justify-end items-center mt-5">
                                                                <button type="button" :disabled="sub_part.loading == true" class="btn btn-secondary w-24 m-auto mr-3 btn-sm" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                <button type="button" @click="add_sub_part" :disabled="sub_part.loading == true" class="btn btn-primary btn-sm w-28 m-auto"> Add Part
                                                                    <LogInIcon v-if="sub_part.loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                    <LoadingIcon v-if="sub_part.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END: Input -->
                            </div>
                        </div>
                        <!-- END: Data List -->
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: Single Process Assign Modal -->
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import Tabulator from 'tabulator-tables'
// import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
import VueBarcode from '@chenfengyuan/vue-barcode';
import cash from 'cash-dom';

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime,
        VueBarcode
    },
    props: {
        rough_id: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            packet_details: [],
            sub_packets: [],
            packet_item: [],
            scan_load: false,
            part_ready: false,
            enable_return: false,
            user_details: [],
            diamond: {
                colors: [],
                cuts: [],
                purities: [],
                symmetry: [],
                polish: [],
                fluerocents: [],
                tensions: [],
                stones: [],
                shapes: [],
                heights: [],
                ratios: [],
                tabls: [],
            },

            costing_value: 0,
            costing_value_manager: 0,
            majuri_total_id: 1,
            majuri_details: [{
                id: 1,
                weight: '',
                majuri: '',
            }],
            majuri_total: {
                weight: 0,
                majuri: 0,
            },
            menual_total: {
                is_ls_weight: false,
                rough_weight: 0,
                weight: 0,
                polish_weight: 0,
                value: 0,
                return_weight: 0,
                ghat_weight: 0,
                assortment_weight: 0,
            },
            last_pack_id: '',
            enable_return: false,
            scan_lot_no: '',

            sub_part: {
                packet_no: '',
                vepari_no: '',
                packet_id: '',
                weight: '',
                new_weight: '',
                loading: false
            }
        }
    },
    computed: {

    },
    mounted() {
        this.getDiamontData();

        this.user_details = this.$store.state.user.currentUser
    },

    methods: {
        async scanAndAddPack() {
            let _this = this;
            _this.clearSearch(this.scan_lot_no);
            this.scan_load = true;
            this.sub_packets = []

            //get lot details
            const data = {
                barcode: this.scan_lot_no,
            };
            let url_post = '';
            if (this.scan_lot_no != '') {
                url_post = "api/process/get_packet_details_for_recive_ls"
            } else {
                url_post = "api/process/get_packet_details_for_recive_ls"
            }
            let promise = axios({
                url: url_post,
                method: "post",
                data: data,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                    this.packet_details = data.plan_details
                    this.packet_item = data.packet_details
                    this.sub_packets = data.sub_packets

                    if (this.packet_item.main_transaction_status == 1 && this.packet_item.part_ready == null) {

                        if (data.plan_details.length > 0) {

                            this.majuri_details[0]['weight'] = this.packet_item.cr_weight

                            this.packet_details.forEach(element => {
                                element.ls_check = false
                            })

                            if (this.packet_item.owner_value) {
                                this.costing_value = parseFloat(parseFloat(this.packet_item.owner_value) + parseFloat(this.packet_item.majuri_type == 1 ? (this.packet_item.owner_value * this.packet_item.majuri_rate / 100) : this.packet_item.weight * this.packet_item.majuri_rate) + parseFloat(this.packet_item.margin_percent * this.packet_item.owner_value / 100)).toFixed(2)
                                // this.costing_value = 1
                                this.costing_value_manager = parseFloat(parseFloat(this.packet_item.owner_value) + parseFloat(this.packet_item.majuri_type == 1 ? (this.packet_item.owner_value * this.packet_item.majuri_rate / 100) : this.packet_item.weight * this.packet_item.majuri_rate)).toFixed(2)
                                console.warn(this.costing_value)
                            }

                            this.getPlanningTotal()

                            this.enable_return = true;

                        } else {

                            _this.clearSearch('');
                            this.$swal("Warning!", "Packet Details Not Found!", "warning");

                        }

                    } else {

                        if (this.packet_item) {
                            this.packet_item.cr_weight = this.packet_item.weight_loss
                        }
                    }
                    this.scan_load = false;

                })
                .catch((_error) => {
                    this.scan_load = false;
                    return [];
                });
        },
        clearSearch(lot_no) {
            this.scan_lot_no = lot_no;
            this.packet_details = [];
            this.packet_item = [];
            this.sub_packets = [];
            this.menual_total.weight = 0;
            this.menual_total.polish_weight = 0;
            this.menual_total.value = 0;
            this.menual_total.ghat_weight = 0;
            this.menual_total.assortment_weight = 0;
            this.scan_load = false
            this.enable_return = false
            this.majuri_total_id = 1
            this.majuri_details = [{
                id: 1,
                weight: '',
                majuri: '',
            }]
            this.majuri_total = {
                weight: 0,
                majuri: 0,
            }
        },
        getDiamontData() {
            axios({
                url: 'api/master/get_all_parameters',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                this.diamond.colors = res.data.color
                this.diamond.cuts = res.data.cut
                this.diamond.purities = res.data.purity
                this.diamond.polish = res.data.polish
                this.diamond.shapes = res.data.shape
                this.diamond.symmetry = res.data.symmetry
                this.diamond.fluerocents = res.data.fluerocent
                this.diamond.tensions = res.data.tension
                this.diamond.stones = res.data.stone
                this.diamond.heights = res.data.height
                this.diamond.tabls = res.data.tabl
                this.diamond.ratios = res.data.ratio
            })
        },
        removeField(id) {
            this.$swal({
                title: "Are you sure?",
                text: "You are about to delete a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = this.packet_details.length;
                    while (i--) {
                        if (this.packet_details[i]['id'] == id) {
                            this.packet_details.splice(i, 1);
                        }
                    }
                    this.getPlanningTotal()
                }
            });
        },
        loadPlanningValues(plan) {
            if (plan.polish_weight != '' && plan.weight != '') {
                if (plan.polish_weight > plan.weight) {

                    this.$swal("Warning!", "Polish Must Less then Makeable Weight!", "warning")
                    var i = this.packet_details.length;
                    while (i--) {
                        if (this.packet_details[i]['id'] == plan.id) {
                            this.packet_details[i]['polish_weight'] = ''
                            return
                        }
                    }
                }
            }
            this.getPlanningTotal()
            if (plan.polish_weight != '' && plan.color != '' && plan.cut != '' && plan.purity != '' && plan.polish != '' && plan.shape != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.height != '' && plan.ratio != '' && plan.tabl != '') {

                let shape_name = ''
                var i = this.diamond.shapes.length;
                while (i--) {
                    if (this.diamond.shapes[i]['id'] == plan.shape) {
                        shape_name = this.diamond.shapes[i]['shape_group']
                    }
                }
                let params = {
                    color: plan.color,
                    cut: plan.cut,
                    purity: plan.purity,
                    polish: plan.polish,
                    symmetry: plan.symmetry,
                    fluerocent: plan.fluerocent,
                    tension: plan.tension,
                    height: plan.height,
                    ratio: plan.ratio,
                    tabl: plan.tabl,
                    shape: plan.shape,
                    shape_group: shape_name,
                    polish_weight: plan.polish_weight,
                    stone: plan.stone,
                }
                axios({
                    url: 'api/plan/get_plan_values',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    let rap_value = res.data.rape_value
                    let back = res.data.back

                    var i = this.packet_details.length;
                    while (i--) {
                        if (this.packet_details[i]['id'] == plan.id) {
                            this.packet_details[i]['r_rate'] = parseFloat(rap_value * plan.polish_weight).toFixed(2)
                            this.packet_details[i]['discount'] = back
                            this.packet_details[i]['value'] = this.packet_details[i]['r_rate'] - (this.packet_details[i]['r_rate'] * back / 100)
                        }
                    }
                    this.getPlanningTotal()
                })
            }

        },
        getPlanningTotal() {
            let length = this.packet_details.length

            this.menual_total.weight = 0
            this.menual_total.polish_weight = 0
            this.menual_total.value = 0

            if (this.menual_total.is_ls_weight) {

                for (let i = 0; i < length; i++) {
                    if (i == (length - 1)) {
                        this.last_pack_id = this.packet_details[i]['id']
                    }

                    if (this.packet_details[i]['ls_check']) {

                        this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.packet_details[i]['weight'] ? this.packet_details[i]['weight'] : 0)).toFixed(4)
                        this.menual_total.polish_weight = parseFloat(parseFloat(this.menual_total.polish_weight) + parseFloat(this.packet_details[i]['polish_weight'] ? this.packet_details[i]['polish_weight'] : 0)).toFixed(4)
                        this.menual_total.value = parseFloat(parseFloat(this.menual_total.value) + parseFloat(this.packet_details[i]['value'] ? this.packet_details[i]['value'] : 0)).toFixed(2)

                        if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_item.cr_weight)) {
                            this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.packet_details[i]['weight'] ? this.packet_details[i]['weight'] : 0)).toFixed(4)
                            this.packet_details[i]['weight'] = '';
                            this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                        }
                    }

                    if (parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.menual_total.rough_weight) + parseFloat(this.menual_total.assortment_weight)) > parseFloat(this.packet_item.cr_weight)) {
                        this.menual_total.assortment_weight = 0
                        this.menual_total.ghat_weight = 0
                        this.menual_total.rough_weight = 0
                        this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");

                    }
                    this.menual_total.ghat_weight = parseFloat((this.packet_item.cr_weight ? this.packet_item.cr_weight : 0) - (parseFloat((this.menual_total.weight ? this.menual_total.weight : 0)) + parseFloat((this.menual_total.assortment_weight ? this.menual_total.assortment_weight : 0)) + parseFloat((this.menual_total.rough_weight ? this.menual_total.rough_weight : 0)))).toFixed(4)

                }

            } else {
                for (let i = 0; i < length; i++) {
                    console.log(this.packet_details.length)
                    if (i == (length - 1)) {
                        this.last_pack_id = this.packet_details[i]['id']
                    }
                    this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight ? this.menual_total.weight : 0) + parseFloat(this.packet_details[i]['weight'] ? this.packet_details[i]['weight'] : 0)).toFixed(4)

                    this.menual_total.polish_weight = parseFloat(parseFloat(this.menual_total.polish_weight) + parseFloat(this.packet_details[i]['polish_weight'] ? this.packet_details[i]['polish_weight'] : 0)).toFixed(4)
                    this.menual_total.value = parseFloat(parseFloat(this.menual_total.value) + parseFloat(this.packet_details[i]['value'] ? this.packet_details[i]['value'] : 0)).toFixed(2)

                    console.log(this.menual_total.weight)
                    console.log(this.packet_item.cr_weight)
                    if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_item.cr_weight)) {
                        this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.packet_details[i]['weight'] ? this.packet_details[i]['weight'] : 0)).toFixed(4)
                        this.packet_details[i]['weight'] = '';
                        this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                    }

                    if ((parseFloat(this.menual_total.weight) + parseFloat(this.menual_total.assortment_weight)) > parseFloat(this.packet_item.cr_weight)) {
                        this.menual_total.assortment_weight = ''
                        this.menual_total.ghat_weight = ''
                        // this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                    }
                    this.menual_total.ghat_weight = parseFloat((this.packet_item.cr_weight ? this.packet_item.cr_weight : 0) - (parseFloat((this.menual_total.weight ? this.menual_total.weight : 0)) + parseFloat((this.menual_total.assortment_weight ? this.menual_total.assortment_weight : 0)))).toFixed(4)

                }

            }

        },
        addNewPart() {
            this.last_pack_id = this.last_pack_id + 1
            this.packet_details.push({
                id: this.last_pack_id,
                weight: '',
                polish_weight: '',
                ls_check: false,
                color: '',
                cut: '',
                purity: '',
                polish: '',
                shape: '',
                symmetry: '',
                fluerocent: '',
                tension: '',
                stone: this.packet_item.stone,
                height: '',
                ratio: '',
                tabl: '',
                r_rate: '',
                value: '',
                discount: '',
            })
        },
        addnewMajuriWeight() {
            this.majuri_total_id = this.majuri_total_id + 1
            this.majuri_details.push({
                id: this.majuri_total_id,
                weight: '',
                majuri: '',
            })
        },
        calculateMajuri() {
            let length = this.majuri_details.length

            this.majuri_total.weight = 0
            this.majuri_total.majuri = 0

            for (let i = 0; i < length; i++) {

                console.log(this.majuri_details[i])

                this.majuri_total.weight = parseFloat(parseFloat(this.majuri_total.weight) + parseFloat(this.majuri_details[i]['weight'] ? this.majuri_details[i]['weight'] : 0)).toFixed(4)
                this.majuri_total.majuri = parseFloat(parseFloat(this.majuri_total.majuri) + parseFloat(this.majuri_details[i]['majuri'] ? this.majuri_details[i]['majuri'] : 0)).toFixed(2)
            }

        },
        removenewMajuriWeight(item) {

            this.$swal({
                title: "Are you sure?",
                text: "You are about cancel Weight!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = this.majuri_details.length;
                    while (i--) {
                        if (this.majuri_details[i]['id'] == item.id) {
                            this.majuri_details.splice(i, 1);
                        }
                    }
                }
                this.getsubmitdetails()
                this.calculateMajuri()
            });
        },
        submitReturnRough() {
            let submit = true

            // if(this.menual_total.is_ls_weight) {

            //   this.packet_details.forEach(plan => {
            //     if(plan.ls_check == true) {
            //       if(plan.weight == '' || plan.polish_weight == '' || plan.color == '' || plan.cut == '' || plan.purity == '' || plan.polish == '' || plan.shape == '' || plan.symmetry == '' || plan.fluerocent == '' || plan.height == '' || plan.ratio == '' || plan.tabl == '') {
            //         this.$swal("Warning!", "Planning Fileds are Empty !", "warning")
            //         submit = false
            //       }
            //     }
            //   });
            // } else {

            // }
            this.packet_details.forEach(plan => {
              if (!(plan.weight) || !(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                    this.$swal("Warning!", "Planning Fileds are Empty !", "warning")
                    submit = false
                }
            });
            // this.majuri_details.forEach(plan => {
            //   if(plan.weight == '' || plan.majuri == '') {
            //     this.$swal("Warning!", "Majuri Fields are Empty !", "warning")
            //     submit = false
            //   }
            // });
            if (submit == true) {

                this.$swal({
                    title: "Are you sure?",
                    text: "You are You Want To Return!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Submit",
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Cancel",

                    allowOutsideClick: () => !$Swal.isLoading()
                }).then(result => {
                    if (result.isConfirmed) {

                        let params = {
                            plan_details: this.packet_details,
                            menual_total: this.menual_total,
                            packet_id: this.packet_item.id,
                            trans_id: this.packet_item.transaction_id,
                            majuri_details: this.majuri_details,
                            majuri_total: this.majuri_total,
                        }

                        let promise = axios({
                            url: 'api/process/return_ls_receive_party',
                            method: "post",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            },
                        });
                        return promise
                            .then((result) => result.data)
                            .then((data) => {
                                if (data.status == 'success') {
                                    this.$swal("Success!", "Packets Updated.", "success");
                                    this.scanAndAddPack()
                                } else {

                                    this.$swal("Warning!", "Somthing Wants Wrong!", "warning");
                                }
                            })
                    }
                });

            }
        },
        set_part_details() {
            cash('#add-packet-part-modal').modal('show')
            this.sub_part.packet_no = this.packet_item.number
            this.sub_part.vepari_no = this.packet_item.vepari_no
            this.sub_part.packet_id = this.packet_item.id
            this.sub_part.weight = this.packet_item.cr_weight
            this.sub_part.new_weight = ''
        },
        add_sub_part() {

            let cr_weight = this.sub_part.weight

            if (parseFloat(cr_weight) <= parseFloat(this.sub_part.new_weight)) {
                this.$swal('Error', 'Not allowed to add a piece with invalid weight!.', 'error');
                return
            }

            const params = {
                packet_no: window.btoa(this.sub_part.packet_no),
                vepari_no: window.btoa(this.sub_part.vepari_no),
                packet_id: window.btoa(this.sub_part.packet_id),
                weight: window.btoa(this.sub_part.weight),
                nw: window.btoa(this.sub_part.new_weight),
            }

            let promise_url = 'api/kpn/rpc-asp'
            this.sub_part.loading = true

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.status == 'error') {
                        this.$swal('Warning', data.message, 'warning');
                        this.sub_part.loading = false
                    } else {
                        this.$swal('Success', data.message, 'success');

                        this.sub_part.packet_no = ''
                        this.sub_part.vepari_no = ''
                        this.sub_part.packet_id = ''
                        this.sub_part.weight = ''
                        this.sub_part.new_weight = ''
                        this.sub_part.loading = false

                        cash('#add-packet-part-modal').modal('hide')
                        this.scanAndAddPack()
                    }
                })
                .catch(_error => {
                    this.sub_part.loading = false
                    this.$swal('Error', 'Something went wrong.', 'error');
                });
        },
        // getMajuri_details(item) {

        //   this.getsubmitdetails()

        //   this.calculateMajuri()
        //   let params = {
        //     weight : item.weight,
        //     packet_id : this.packet_item.id
        //   }
        //   axios({
        //       url: 'api/process/get_ls_majuri_details',
        //       method: 'post',
        //       baseURL: BASE_URL,
        //       data: params,
        //       headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        //       config: { headers: { 'Accept':'application/json' } }
        //     }).then((res) => {

        //       let rate = res.data.rate

        //       if(rate) {

        //         var i = this.majuri_details.length;
        //             while(i--){
        //             if(this.majuri_details[i]['id'] == item.id ){
        //                 this.majuri_details[i]['majuri'] = parseFloat(rate * this.majuri_details[i]['weight']).toFixed(2)
        //             }
        //           }
        //           this.calculateMajuri()
        //       }
        //     })
        // },
        getsubmitdetails() {

            var length = this.majuri_details.length;
            var length2 = this.packet_details.length;

            for (let i = 0; i < length; i++) {
                if (i != 0) {
                    if (this.majuri_details[i]['weight'] > this.majuri_details[i - 1]['weight']) {
                        this.$swal("Warning!", "Majuri Weight is Not Correct!", "warning");
                        this.majuri_details[i]['weight'] = ''
                        this.majuri_details[i]['majuri'] = ''
                    }
                }
            }
        },
        update_print_status(item) {

let params = {
  packet_id: window.btoa(item.id)
}

axios({
    url: 'api/process/update_print_status',
    method: 'post',
    baseURL: BASE_URL,
    data: params,
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
    },
    config: {
        headers: {
            'Accept': 'application/json'
        }
    }
}).then((res) => {

  if(res.data.status == 'error') {

    this.$swal("Warning!", res.data.message, "warning")
  } else if(res.data.status == 'success') {

    item.print_status = false
  }
}).catch((err) => {
  this.loading = false
  this.$swal("Warning!", "Something went wrong.", "warning")
})

}
    },
    //   watch: {
    //   // whenever question changes, this function will run
    //   scan_lot_no(newQuestion, oldQuestion) {
    //     if (this.scan_lot_no != '') {
    //       this.scanAndAddPack()
    //     }
    //   }
    // },

})
</script>
