<template>
  <div>
      <div class="intro-y flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Kapan Report</h2>
      </div>
      <div class="intro-y box p-5 mt-5" style="z-index: 50;">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="page_data.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                        Generate
                    </button>
                </div>
            </div>
        </div>
      </div>
      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">

              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Rough Details</h2>
                      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                          <router-link :to="`/purchase/update/` + this.$route.params.id" class="btn btn-primary shadow-md mr-2" v-if="transaction_details.status == 0 && transaction_details.man_status == 1 && transaction_details.hold_transaction_id == null && transaction_details.pr_status == null && transaction_details.manager_id == user_details.account_id">Add Pieces</router-link>
                      </div>
                  </div>
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 lg:col-span-5">

                              <div>
                                  <label for="update-profile-form-6" class="form-label font-bold">Purchase No. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.lot_no }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Kapan No. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.voucher_no }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Party. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.party_first_name }} {{ transaction_details.party_last_name }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Purchase Date. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.created_at }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Status. :&nbsp;</label><label for="update-profile-form-6" class="form-label">
                                      <span v-if="transaction_details.status == 1 || transaction_details.status == 0" class="text-theme-1 font-bold">Active</span>
                                      <span v-if="transaction_details.status == 2" class="text-theme-11 font-bold">In Process</span>
                                      <span v-if="transaction_details.status == 4" class="text-theme-9 font-bold">Delivered</span>
                                      <span v-if="transaction_details.status == 3" class="text-theme-6 font-bold">Cancelled</span>
                                  </label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Last Manager. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.manager_first_name }} {{ transaction_details.manager_last_name }}</label>
                              </div>

                          </div>
                          <div class="col-span-12 lg:col-span-5">

                            <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Purchase PCS. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.pr_pcs }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Purchase WT. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.pr_wt }} Ct</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold" v-if="transaction_details.pr_status == null">Cr. Rough PCS {{ transaction_details.current_lot_weight > 0 ? '/ Final PCS' : '' }} :&nbsp;</label><label for="update-profile-form-6" class="form-label" v-if="transaction_details.pr_status == null"> {{ transaction_details.cr_pcs ? transaction_details.cr_pcs : 0 }} {{ transaction_details.current_lot_pieces ? '  / ' + transaction_details.current_lot_pieces : '' }}</label>
                                  <label for="update-profile-form-6" class="form-label font-bold" v-if="transaction_details.pr_status == 2">Cr. Stock PCS :&nbsp;</label><label for="update-profile-form-6" class="form-label" v-if="transaction_details.pr_status == 2"> {{ transaction_details.current_lot_pieces ? transaction_details.current_lot_pieces : '' }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold" v-if="transaction_details.pr_status == null">Cr. Rough Weight {{ transaction_details.current_lot_weight > 0 ? '/ Final Weight' : '' }} :&nbsp;</label><label for="update-profile-form-6" class="form-label" v-if="transaction_details.pr_status == null"> {{ transaction_details.cr_wt ? $h.myFloat(transaction_details.cr_wt) + ' Ct' : ' 0 Ct' }} {{ transaction_details.current_lot_weight ? '  / ' + transaction_details.current_lot_weight+ ' Ct' : '' }}</label>
                                  <label for="update-profile-form-6" class="form-label font-bold" v-if="transaction_details.pr_status == 2">Cr. Stock Weight :&nbsp;</label><label for="update-profile-form-6" class="form-label" v-if="transaction_details.pr_status == 2">  {{ transaction_details.current_lot_weight ? transaction_details.current_lot_weight+ ' Ct' : '' }}</label>
                              </div>
                              <!-- <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">In Process PCS. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.inpr_pcs ? transaction_details.inpr_pcs : 0 }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">In Process Weight. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.inpr_wt ? $h.myFloat(transaction_details.inpr_wt) : 0 }} Ct</label>
                              </div> -->
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Direct Rejection PCS. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.rejection_pcs ? transaction_details.rejection_pcs : 0 }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Direct Rejection Weight. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.rejection_wt ? $h.myFloat(transaction_details.rejection_wt) : 0 }} Ct</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Process Rejection PCS. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.fail_pcs ? transaction_details.fail_pcs : 0 }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Process Rejection Weight. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat((transaction_details.fail_wt ? $h.myFloat(transaction_details.fail_wt) : 0) + (transaction_details.total_assortment ? $h.myFloat(transaction_details.total_assortment) : 0)) }} Ct</label>
                              </div>
                              <!-- <div class="mt-2" v-if="transaction_details.total_assortment">
                                  <label for="update-profile-form-6" class="form-label font-bold">Process Rejection Weight. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.total_assortment ? $h.myFloat(transaction_details.total_assortment) : 0 }} Ct</label>
                              </div> -->
                              <!-- <div class="mt-2" v-if="transaction_details.total_net_assortment">
                                  <label for="update-profile-form-6" class="form-label font-bold">Net Rejection Weight. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.total_net_assortment ? $h.myFloat(transaction_details.total_net_assortment) : 0 }} Ct</label>
                              </div> -->
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Net Rejection PCS. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.net_rejection_pcs ? transaction_details.net_rejection_pcs : 0 }}</label>
                              </div>
                              <div class="mt-2">
                                  <label for="update-profile-form-6" class="form-label font-bold">Net Rejection Weight. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat((transaction_details.net_rejection_wt ? $h.myFloat(transaction_details.net_rejection_wt) : 0) + (transaction_details.total_net_assortment ? $h.myFloat(transaction_details.total_net_assortment) : 0)) }} Ct</label>
                              </div>
                              <div class="mt-2" v-if="transaction_details.ghat_wt">
                                  <label for="update-profile-form-6" class="form-label font-bold">Bunch Rough Lose. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.ghat_wt ? $h.myFloat(transaction_details.ghat_wt) : 0 }} Ct</label>
                              </div>
                              <div class="mt-2" v-if="transaction_details.total_lose">
                                  <label for="update-profile-form-6" class="form-label font-bold">Lot Process Lose. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.total_lose ? $h.myFloat(transaction_details.total_lose) : 0 }} Ct</label>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="intro-y box my-5 py-5">
                  <div class="col-span-12 xl:col-span-12">
                      <div class="flex flex-col sm:flex-row items-center pt-2 p-5 border-b border-gray-200 dark:border-dark-5">
                          <h2 class="font-medium text-base mr-auto pl-5">Kapan Manager</h2>
                      </div>
                      <div class="m-5">
                          <div class="border">
                              <div class="overflow-x-auto">
                                  <table class="w-full text-center text-xs">
                                      <thead>
                                          <tr class="bg-theme-14">
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Sr. No</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Kapan No</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Manager Name</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Issue PCS</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Issue WT(Ct)</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Ghat WT(Ct)</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Direct Rejection</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Process Rejection</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Net Rejection</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Issue Date</th>
                                          </tr>
                                      </thead>
                                      <tbody class="text-center" v-for="(field,index) in kapan_managers" :key="index">
                                          <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}">
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ index + 1 }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.lot_no ? field.lot_no : "-" }} ({{ field.voucher_no }})</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{field.manager_first_name}} <br> {{ field.manager_last_name }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{(field.issue_pieces ? field.issue_pieces : 0)}}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{(field.issue_weight ? field.issue_weight : 0)}} Ct</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{(field.lose_weight ? field.lose_weight : 0)}} Ct</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{(field.d_rejection_pieces ? field.d_rejection_pieces + 'Pcs' : '-')}} <br>{{(field.d_rejection_weight ? field.d_rejection_weight : 0)}} Ct</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{(field.rejection_pieces ? field.rejection_pieces + 'Pcs' : '-')}} <br>{{(field.rejection_weight ? field.rejection_weight : 0)}} Ct</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{(field.rejection_pieces ? field.net_rejection_pieces + 'Pcs' : '-')}} <br>{{(field.net_rejection_weight ? field.net_rejection_weight : 0)}} Ct</td>
                                              <td class="border-b border-r py-2 px-2 w-20">{{ field.created_at }}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  <div class="text-center py-2" v-if="kapan_managers.length == 0">
                                      Records not Found
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="intro-y box my-5 py-5">
                  <div class="col-span-12 xl:col-span-12">
                      <div class="flex flex-col sm:flex-row items-center pt-2 p-5 border-b border-gray-200 dark:border-dark-5">
                          <h2 class="font-medium text-base mr-auto pl-5">Bunch Process Details</h2>
                      </div>
                      <div class="m-5">
                          <div class="border">
                              <div class="overflow-x-auto">
                                  <table class="w-full text-center text-xs">
                                      <thead>
                                          <tr class="bg-theme-14">
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Sr. No</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Transaction No</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Issue PCS<br> Issue WT</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Rec. PCS<br> Rec. WT</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Rejection PCS<br> Rejection WT</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Lose WT</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Manager / Wroker</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Process</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Issue Date <br> Receive Date</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Status</th>
                                          </tr>
                                      </thead>
                                      <tbody class="text-center" v-for="(field,index) in transaction_packets" :key="index">
                                          <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}">
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ index + 1 }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.transaction_code ? field.transaction_code : "-" }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{field.issue_pieces}} <br> {{ field.issue_weight + ' Ct' }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{field.receive_pieces ? field.receive_pieces : '-'}} <br> {{ field.receive_weight ? (field.receive_weight + ' Ct') : '-' }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{field.fail_pieces ? field.fail_pieces : '-'}} <br> {{ field.fail_weight ? (field.fail_weight + ' Ct') : '-' }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ field.lose_weight ? (field.lose_weight + ' Ct') : '-' }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">
                                                  <label class="p-2 mt-3">
                                                      <label class="p-2 mt-3">
                                                          <span v-if="field.issue_by == user_details.id">
                                                              <span v-if="field.transaction_type == 2">
                                                                  <span v-if="field.process_type == 1 || field.process_type == 2 || field.process_type == 5 || field.process_type == 6">

                                                                      <span v-if="field.employee_role == 4">
                                                                          {{ field.pp_first_name }} {{ field.pp_last_name }}
                                                                      </span>
                                                                      <span v-if="field.employee_role == 7">
                                                                          {{ field.karigar_first_name }} {{ field.karigar_last_name }}
                                                                      </span>
                                                                  </span>
                                                              </span>
                                                          </span>
                                                      </label>

                                                  </label></td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap"><span class="text-theme-9 font-12 font-bold">{{ field.process_name }}</span></td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{field.created_at}} <br> {{ (field.receive_date ? field.receive_date : '-') }}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap"><label class="p-2 mt-3">
                                                      <span v-if="field.transaction_type == 1 || field.transaction_type == 4 || field.transaction_type == 3 || (field.transaction_type == 2 && (field.process_type  == 5 || field.process_type  == 6))">
                                                          <span v-if="field.status == 1 && field.transaction_status == 1" class="text-theme-11 font-bold">Hold</span>
                                                          <span v-if="field.status == 1 && field.transaction_status == 2" class="text-theme-9 font-bold">Accepted</span>
                                                          <span v-if="field.status == 3 || field.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>

                                                      </span>
                                                      <span v-else>
                                                          <span v-if="field.status == 1 && field.transaction_status == 2" class="text-theme-1 font-bold">
                                                              <!-- <button class="btn btn-primary btn-sm text-center">
                                                              <EditIcon class=" h-4 w-4" /> Receive
                                                              </button> -->
                                                              In Process</span>
                                                          <span v-if="field.status == 2 && field.transaction_status == 2" class="text-theme-9 font-bold">Received</span>
                                                          <span v-if="field.status == 3 || field.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>
                                                      </span></label></td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  <div class="text-center py-2" v-if="transaction_packets.length == 0">
                                      Records not Found
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="intro-y box my-5 py-5">
                  <div class="col-span-12 xl:col-span-12">
                      <div class="flex flex-col sm:flex-row items-center pt-2 p-5 border-b border-gray-200 dark:border-dark-5">
                          <h2 class="font-medium text-base mr-auto pl-5">Packet Details</h2>
                      </div>
                      <div class="m-5">
                          <div class="">
                              <div class="overflow-x-auto">
                                  <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="intro-y box my-5 py-5">
                  <div class="col-span-12 xl:col-span-12">
                    <div class="flex flex-col sm:flex-row items-center pt-2 p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto pl-5">Polish Summary Details</h2>
                    </div>
                  </div>
                  <div class="col-span-6 md:col-span-3 xl:col-span-6 m-5">
                      <div class="mt-2">
                          <label for="update-profile-form-6" class="form-label">Comparisons</label>
                          <TomSelect v-model="page_data.compare_items_2" :options="{
                        placeholder: 'Select Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Process'
                          }
                        }
                      }" class="w-full" multiple>
                              <option value="1">Planning</option>
                              <option value="2">Makeable</option>
                              <option value="3">Final Polish</option>
                              <option value="4">LAB</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-12 xl:col-span-12">

                      <div class="m-5">
                          <div class="">
                            <div class="scrollbar-hidden" v-if="planning_stocks.length > 0">
                                <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                                  <table class="w-full border-0">
                                        <thead>
                                            <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">KAPAN NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PACKET NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">STOCK NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">STONE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PACKET WT(CT)</th>
                                                <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PARTY / MARKER NAME</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PLAN DATE</th> -->
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="4" v-if="page_data.compare_items_2.includes('1')">PLANNING</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="6" v-if="page_data.compare_items_2.includes('2')">MAKEABLE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="4" v-if="page_data.compare_items_2.includes('3')">FINAL POLISH</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="4" v-if="page_data.compare_items_2.includes('4')">LAB</th>
                                              </tr>
                                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th> -->
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">MAK. WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">MAK. (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">EXP WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">EXP (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">MAK. WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">MAK. (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">EXP (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">EXP WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">DIFF WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">DIFF (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">FINAL WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">DIFF WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">FINAL (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">DIFF (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">LAB WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">DIFF WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">LAB (%)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">DIFF (%)</th>
                                              </tr>
                                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ total.total_packets ? total.total_packets : '0' }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (total.total_issue_weight ? $h.myFloat(total.total_issue_weight) : '0') }}</th>
                                                <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th> -->
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (total.total_planning_weight ? $h.myFloat(total.total_planning_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (total.total_planning_percent ? $h.myFloat(total.total_planning_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (total.total_planning_polish_weight ? $h.myFloat(total.total_planning_polish_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (total.total_planning_polish_percent ? $h.myFloat(total.total_planning_polish_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (total.total_makeable_weight ? $h.myFloat(total.total_makeable_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (total.total_makeable_percent ? $h.myFloat(total.total_makeable_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (total.total_makeable_polish_weight ? $h.myFloat(total.total_makeable_polish_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (total.total_makeable_polish_percent ? $h.myFloat(total.total_makeable_polish_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (total.total_diff_makeable_weight ? $h.myFloat(total.total_diff_makeable_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (total.total_diff_makeable_percent ? $h.myFloat(total.total_diff_makeable_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (total.total_final_weight ? $h.myFloat(total.total_final_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (total.total_final_percent ? $h.myFloat(total.total_final_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (total.total_diff_final_weight ? $h.myFloat(total.total_diff_final_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (total.total_diff_final_percent ? $h.myFloat(total.total_diff_final_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (total.total_lab_weight ? $h.myFloat(total.total_lab_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (total.total_lab_percent ? $h.myFloat(total.total_lab_percent) + ' %' : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (total.total_diff_lab_weight ? $h.myFloat(total.total_diff_lab_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (total.total_diff_lab_percent ? $h.myFloat(total.total_diff_lab_percent) + ' %' : '0') }}</th>
                                              </tr>
                                        </thead>
                                            <tbody>

                                              <template v-for="(main_pack,index) in planning_stocks" :key="index">

                                                  <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                                      <td class="p-2 border-b border-t text-xs lg:text-md text-center border-r">{{ main_pack.lot_no }} ({{ main_pack.voucher_no }})</td>
                                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.number }}</td>
                                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.vepari_no }}</td>
                                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.stone_name }}</td>
                                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.weight }}</td>
                                                      <!-- <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.planning_employee_short_name }}</td>
                                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.pl_date }}</td> -->
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (main_pack.total_planning_weight ? $h.myFloat(main_pack.total_planning_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (main_pack.total_planning_percent ? $h.myFloat(main_pack.total_planning_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (main_pack.total_planning_polish_weight ? $h.myFloat(main_pack.total_planning_polish_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('1')">{{ (main_pack.total_planning_polish_percent ? $h.myFloat(main_pack.total_planning_polish_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (main_pack.total_makeable_weight ? $h.myFloat(main_pack.total_makeable_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (main_pack.total_makeable_percent ? $h.myFloat(main_pack.total_makeable_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (main_pack.total_makeable_polish_weight ? $h.myFloat(main_pack.total_makeable_polish_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (main_pack.total_makeable_polish_percent ? $h.myFloat(main_pack.total_makeable_polish_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (main_pack.total_diff_makeable_weight ? $h.myFloat(main_pack.total_diff_makeable_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('2')">{{ (main_pack.total_diff_makeable_percent ? $h.myFloat(main_pack.total_diff_makeable_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (main_pack.total_final_weight ? $h.myFloat(main_pack.total_final_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (main_pack.total_final_percent ? $h.myFloat(main_pack.total_final_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (main_pack.total_diff_final_weight ? $h.myFloat(main_pack.total_diff_final_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('3')">{{ (main_pack.total_diff_final_percent ? $h.myFloat(main_pack.total_diff_final_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (main_pack.total_lab_weight ? $h.myFloat(main_pack.total_lab_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (main_pack.total_lab_percent ? $h.myFloat(main_pack.total_lab_percent) + ' %' : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (main_pack.total_diff_lab_weight ? $h.myFloat(main_pack.total_diff_lab_weight) : '0') }}</th>
                                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items_2.includes('4')">{{ (main_pack.total_diff_lab_percent ? $h.myFloat(main_pack.total_diff_lab_percent) + ' %' : '0') }}</th>
                                                  </tr>
                                              </template>
                                          </tbody>

                                    </table>
                                </div>
                            </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="intro-y box my-5 py-5">
                  <div class="col-span-12 xl:col-span-12">
                    <div class="flex flex-col sm:flex-row items-center pt-2 p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto pl-5">Polish Lot Details</h2>
                    </div>
                  </div>
                  <div class="col-span-6 md:col-span-3 xl:col-span-6 m-5">
                      <div class="mt-2">
                          <label for="update-profile-form-6" class="form-label">Comparisons</label>
                          <TomSelect v-model="page_data.compare_items" :options="{
                        placeholder: 'Select Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Process'
                          }
                        }
                      }" class="w-full" multiple>
                              <option value="1">Planning</option>
                              <option value="2">Makeable</option>
                              <option value="5">HPHT</option>
                              <option value="3">Final Polish</option>
                              <option value="4">LAB</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-12 xl:col-span-12">
                      <div class="m-5">
                          <div class="">
                            <div class="scrollbar-hidden" v-if="planning_stocks.length > 0">
                                <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                                  <table class="w-full border-0">
                                        <thead>
                                            <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">KAPAN NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PACKET NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">STOCK NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">STONE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PACKET WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PARTY / MARKER NAME</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PLAN DATE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PACKET NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">STOCK NO</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="6" v-if="page_data.compare_items.includes('1')">PLANNING</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="12" v-if="page_data.compare_items.includes('2')">MAKEABLE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="2" v-if="page_data.compare_items.includes('5')">HPHT</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="10" v-if="page_data.compare_items.includes('3')">FINAL POLISH</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="8" v-if="page_data.compare_items.includes('4')">LAB</th>
                                              </tr>
                                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">COLOR</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">CUT</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">CLARITY</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">SHAPE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">PLAN WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">TOTAL PLAN WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">MAK. DATE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">MANAGER NAME</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">WORKER NAME</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">MAK. Wt(Ct)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">MAK. STATUS</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">COLOR</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">CUT</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">CLARITY</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">SHAPE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">EXP WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">DIFF WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">TOTAL EXP WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('5')">STATUS</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('5')">COMMENT</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">REC. DATE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">MANAGER NAME</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">WORKER NAME</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">COLOR</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">CUT</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">CLARITY</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">SHAPE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">POL WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">DIFF WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">TOTAL POLISH(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">REC. DATE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">COLOR</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">CUT</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">CLARITY</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">SHAPE</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">POL WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">DIFF WT(CT)</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">TOTAL POLISH(CT)</th>
                                              </tr>
                                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ total.total_packets ? total.total_packets : '0' }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (total.total_issue_weight ? $h.myFloat(total.total_issue_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">{{ (total.total_planning_polish_weight ? $h.myFloat(total.total_planning_polish_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">{{ (total.total_makeable_weight ? $h.myFloat(total.total_makeable_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">{{ (total.total_makeable_polish_weight ? $h.myFloat(total.total_makeable_polish_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">{{ (total.total_diff_makeable_weight ? $h.myFloat(total.total_diff_makeable_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('5')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('5')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">{{ (total.total_final_weight ? $h.myFloat(total.total_final_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">{{ (total.total_diff_final_weight ? $h.myFloat(total.total_diff_final_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">{{ (total.total_lab_weight ? $h.myFloat(total.total_lab_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">{{ (total.total_diff_lab_weight ? $h.myFloat(total.total_diff_lab_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                              </tr>
                                        </thead>
                                            <tbody>

                                              <template v-for="(main_pack,index) in planning_stocks" :key="index">
                                                <template v-for="(item,inx) in main_pack.packet_details" :key="inx">

                                                  <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (inx % 2) == 0}">
                                                      <template v-if="inx == 0">
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs lg:text-md text-center border-r">{{ main_pack.lot_no }} ({{ main_pack.voucher_no }})</td>
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.number }}</td>
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.vepari_no }}</td>
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.stone_name }}</td>
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.weight }}</td>
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.planning_employee_short_name }}</td>
                                                        <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.pl_date }}</td>
                                                      </template>
                                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.number ? item.number : '-') }}</td>
                                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.vepari_no ? item.vepari_no : '-') }}</td>
                                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="page_data.compare_items.includes('1')">{{ (item.planning_color_name ? item.planning_color_name : '-') }}</td>
                                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="page_data.compare_items.includes('1')">{{ (item.planning_cut_name ? item.planning_cut_name : '-') }}</td>
                                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="page_data.compare_items.includes('1')">{{ (item.planning_purity_name ? item.planning_purity_name : '-') }}</td>
                                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="page_data.compare_items.includes('1')">{{ (item.planning_shape_name ? item.planning_shape_name : '-') }}</td>
                                                    <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="page_data.compare_items.includes('1')">{{ (item.pl_ex_wt ? item.pl_ex_wt : '-') }}</td>
                                                    <td v-if="inx == 0 && page_data.compare_items.includes('1')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.pl_date != null"><span>{{ (main_pack.total_planning_polish_weight ? $h.myFloat(main_pack.total_planning_polish_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_planning_polish_percent ? main_pack.total_planning_polish_percent + ' %' : '-') }}</span></span></td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_date ? item.mk_date : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.makeable_manager_first_name ? item.makeable_manager_first_name : '-' }} {{ (item.makeable_manager_last_name ? item.makeable_manager_last_name : '') }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.makeable_employee_short_name ? item.makeable_employee_short_name : '-' }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_wt ? item.mk_wt : '-') }}</td>
                                                    <th v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.for_p == 1 ? '4P' : (item.semi_polish == 1 ? 'Semi Polish' : '')) }}</th>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_color > item.pl_color) && item.pl_color > 0 && item.mk_color > 0),'text-theme-9' : ((item.pl_color > item.mk_color) && item.pl_color > 0 && item.mk_color > 0)}">{{ (item.makeable_color_name ? item.makeable_color_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_cut > item.pl_cut) && item.pl_cut > 0 && item.mk_cut > 0),'text-theme-9' : ((item.pl_cut > item.mk_cut) && item.pl_cut > 0 && item.mk_cut > 0)}">{{ (item.makeable_cut_name ? item.makeable_cut_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_purity > item.pl_purity) && item.pl_purity > 0 && item.mk_purity > 0),'text-theme-9' : ((item.pl_purity > item.mk_purity) && item.pl_purity > 0 && item.mk_purity > 0)}">{{ (item.makeable_purity_name ? item.makeable_purity_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.makeable_shape_name ? item.makeable_shape_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_ex_wt ? item.mk_ex_wt : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_ex_wt ? $h.myFloat(item.mk_ex_wt - item.pl_ex_wt) : '-') }}</td>
                                                    <td v-if="inx == 0 && page_data.compare_items.includes('2')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.mk_date != null"><span class="m-5">{{ (main_pack.total_makeable_polish_weight ? $h.myFloat(main_pack.total_makeable_polish_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_makeable_polish_percent ? main_pack.total_makeable_polish_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_makeable_percent ? main_pack.total_diff_makeable_percent + ' %' : '-') }}</span></span></td>
                                                    <th v-if="page_data.compare_items.includes('5')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.hp_date ? 'YES' : 'NO') }}</th>
                                                    <th v-if="page_data.compare_items.includes('5')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_comment ? item.issue_comment : '-') }}</th>
                                                    <th v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_date ? item.po_date : '-') }}</th>
                                                    <th v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_manager_first_name ? item.final_manager_first_name : '-' }} {{ (item.final_manager_last_name ? item.final_manager_last_name : '') }}</th>
                                                    <th v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_employee_short_name ? item.final_employee_short_name : '-' }}</th>
                                                    <td v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_color > item.mk_color) && item.po_color > 0 && item.mk_color > 0),'text-theme-9' : ((item.po_color < item.mk_color) && item.po_color > 0 && item.mk_color > 0)}">{{ (item.final_color_name ? item.final_color_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_cut > item.mk_cut) && item.po_cut > 0 && item.mk_cut > 0),'text-theme-9' : ((item.po_cut < item.mk_cut) && item.po_cut > 0 && item.mk_cut > 0)}">{{ (item.final_cut_name ? item.final_cut_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_purity > item.mk_purity) && item.po_purity > 0 && item.mk_purity > 0),'text-theme-9' : ((item.po_purity < item.mk_purity) && item.po_purity > 0 && item.mk_purity > 0)}">{{ (item.final_purity_name ? item.final_purity_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_shape_name ? item.final_shape_name : '-') }}</td>
                                                    <th v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_f_pw ? item.po_f_pw : '-') }}</th>
                                                    <th v-if="page_data.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_f_pw ? $h.myFloat(item.po_f_pw - item.mk_ex_wt) : '-') }}</th>
                                                    <td v-if="inx == 0 && page_data.compare_items.includes('3')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.po_date != null"><span class="m-5">{{ (main_pack.total_final_weight ? $h.myFloat(main_pack.total_final_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_final_percent ? main_pack.total_final_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_final_percent ? main_pack.total_diff_final_percent + ' %' : '-') }}</span></span></td>
                                                    <td v-if="page_data.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_date ? item.lab_date : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_color > item.po_color) && item.po_color > 0 && item.lab_color > 0),'text-theme-9' : ((item.po_color > item.lab_color) && item.po_color > 0 && item.lab_color > 0)}">{{ (item.lab_color_name ? item.lab_color_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_cut > item.po_cut) && item.po_cut > 0 && item.lab_cut > 0),'text-theme-9' : ((item.po_cut > item.lab_cut) && item.po_cut > 0 && item.lab_cut > 0)}">{{ (item.lab_cut_name ? item.lab_cut_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_purity > item.po_purity) && item.po_purity > 0 && item.lab_purity > 0),'text-theme-9' : ((item.po_purity > item.lab_purity) && item.po_purity > 0 && item.lab_purity > 0)}">{{ (item.lab_purity_name ? item.lab_purity_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_shape_name ? item.lab_shape_name : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_wt ? item.lab_wt : '-') }}</td>
                                                    <td v-if="page_data.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_wt ? $h.myFloat(item.lab_wt - item.po_f_pw) : '-') }}</td>
                                                    <td v-if="inx == 0 && page_data.compare_items.includes('4')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.lab_date != null"><span class="m-5">{{ (main_pack.total_lab_weight ? $h.myFloat(main_pack.total_lab_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_lab_percent ? main_pack.total_lab_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_lab_percent ? main_pack.total_diff_lab_percent + ' %' : '-') }}</span></span></td>
                                                  </tr>
                                                </template>
                                              </template>

                                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (total.total_packets ? total.total_packets : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (total.total_issue_weight ? $h.myFloat(total.total_issue_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')">{{ (total.total_planning_polish_weight ? $h.myFloat(total.total_planning_polish_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('1')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">{{ (total.total_makeable_weight ? $h.myFloat(total.total_makeable_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">{{ (total.total_makeable_polish_weight ? $h.myFloat(total.total_makeable_polish_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')">{{ (total.total_diff_makeable_weight ? $h.myFloat(total.total_diff_makeable_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('2')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('5')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('5')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">{{ (total.total_final_weight ? $h.myFloat(total.total_final_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')">{{ (total.total_diff_final_weight ? $h.myFloat(total.total_diff_final_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('3')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">{{ (total.total_lab_weight ? $h.myFloat(total.total_lab_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')">{{ (total.total_diff_lab_weight ? $h.myFloat(total.total_diff_lab_weight) : '0') }}</th>
                                                <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="page_data.compare_items.includes('4')"></th>
                                              </tr>

                                          </tbody>

                                    </table>
                                </div>
                            </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="intro-y box my-5 py-5">
                  <div class="col-span-12 xl:col-span-12">
                      <div class="flex flex-col sm:flex-row items-center pt-2 p-5 border-b border-gray-200 dark:border-dark-5">
                          <h2 class="font-medium text-base mr-auto pl-5">Rejection Details</h2>
                          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                              <button @click="addRejection" class="btn btn-primary shadow-md mr-2" v-if="transaction_details.status == 0 && transaction_details.man_status == 1 && transaction_details.hold_transaction_id == null && transaction_details.pr_status == null && transaction_details.manager_id == user_details.account_id">Add Rejection</button>
                          </div>
                      </div>
                      <div class="m-5">
                          <div class="border">
                              <div class="overflow-x-auto">
                                  <table class="w-full text-center text-xs">
                                      <thead>
                                          <tr class="bg-theme-14">
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Sr. No</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Rejection Type</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Pieces</th>
                                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight (Ct)</th>
                                          </tr>
                                      </thead>
                                      <tbody class="text-center">
                                          <tr class="hover:bg-gray-200 bg-gray-200">
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">Available</th>
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">AVAILABLE REJECTION</th>
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.cr_rejection_pcs ? rejection_details.cr_rejection_pcs : '-'}}</th>
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.cr_rejection_wt ? rejection_details.cr_rejection_wt : 0}} Ct</th>

                                          </tr>
                                          <tr class="hover:bg-gray-200">
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">1</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">DIRECT REJECTION</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.d_rejection_pcs ? rejection_details.d_rejection_pcs : '-'}}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.d_rejection_wt ? rejection_details.d_rejection_wt : 0}} Ct</td>

                                          </tr>
                                          <tr class="hover:bg-gray-200">
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">2</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">PROCESS REJECTION</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.p_rejection_pcs ? rejection_details.p_rejection_pcs : '-'}}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.p_rejection_wt ? rejection_details.p_rejection_wt : 0}} Ct</td>

                                          </tr>
                                          <tr class="hover:bg-gray-200">
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">3</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">NET REJECTION</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.n_rejection_pcs ? rejection_details.n_rejection_pcs : '-'}}</td>
                                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{rejection_details.n_rejection_wt ? rejection_details.n_rejection_wt : 0}} Ct</td>

                                          </tr>
                                          <tr class="hover:bg-gray-200 bg-gray-200">
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">Total</th>
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">TOTAL REJECTION</th>
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{(rejection_details.n_rejection_pcs ? parseFloat(rejection_details.n_rejection_pcs) : 0) + (rejection_details.d_rejection_pcs ? parseFloat(rejection_details.d_rejection_pcs) : 0) + (rejection_details.p_rejection_pcs ? parseFloat(rejection_details.p_rejection_pcs) : 0)}}</th>
                                              <th class="border-b border-r py-2 px-2 whitespace-nowrap">{{($h.myFloat(rejection_details.n_rejection_wt ? parseFloat(rejection_details.n_rejection_wt) : 0) + (rejection_details.d_rejection_wt ? parseFloat(rejection_details.d_rejection_wt) : 0) + (rejection_details.p_rejection_wt ? parseFloat(rejection_details.p_rejection_wt) : 0))}} Ct</th>

                                          </tr>
                                      </tbody>
                                  </table>
                                  <div class="text-center py-2" v-if="rejection_details == []">
                                      Records not Found
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="p-0 m-0 hidden">
        <button id="print-btn" v-print="'#print-barcode'"></button>
          <div id="print-barcode" class="absolute top-0 left-0 p-0 m-0">
              <table class="p-0 m-0 w-full text-black" border="collapse">
                  <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                      <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ packet.polish_weight }}</th>
                  </tr>
                  <tr class="text-center m-0 p-0">
                      <th colspan="9" class="text-center">
                          <VueBarcode :value="packet.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                      </th>
                  </tr>
                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                      <th class="border m-auto border-black">SHP</th>
                      <th class="border m-auto border-black">CL</th>
                      <th class="border m-auto border-black">PRT</th>
                      <th class="border m-auto border-black">CT</th>
                      <th class="border m-auto border-black">PL</th>
                      <th class="border m-auto border-black">SYM</th>
                      <th class="border m-auto border-black">Fl</th>
                      <th class="border m-auto border-black">TN</th>
                      <th class="border m-auto border-black">STN</th>
                  </tr>
                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                      <th class="border m-auto border-black">{{ packet.shape }}</th>
                      <th class="border m-auto border-black">{{ packet.color }}</th>
                      <th class="border m-auto border-black">{{ packet.purity }}</th>
                      <th class="border m-auto border-black">{{ packet.cut }}</th>
                      <th class="border m-auto border-black">{{ packet.polish }}</th>
                      <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                      <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                      <th class="border m-auto border-black">{{ packet.tension }}</th>
                      <th class="border m-auto border-black">{{ packet.stone }}</th>
                  </tr>
                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                      <th class="border m-auto border-black" colspan="3">L</th>
                      <th class="border m-auto border-black" colspan="3">W</th>
                      <th class="border m-auto border-black" colspan="3">H</th>
                  </tr>
                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight == null">
                      <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.length ? packet.length : 0) }}</th>
                      <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.width ? packet.width : 0) }}</th>
                      <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(packet.height ? packet.height : 0) }}</th>
                  </tr>
              </table>
              <p class="p-0 mt-1 text-black w-full"
                                    style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
                                      class="text-left">{{ packet.created_at }}</span> / <span class="text-right">Kodllin
                                      TECHONOLOGY</span></p>
          </div>
      </div>

      <!-- END: Data List -->
      <div id="rough-process-reject-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body p-10">
                      <!-- <div id="vertical-form"> -->
                      <div class="flex flex-col justify-between mt-5">
                          <!-- BEGIN: Data List -->
                          <div class="intro-y overflow-auto">
                              <div class="flex items-center">
                                  <h2 class="intro-y text-lg font-medium mr-5">Add Rejection</h2>
                              </div>
                          </div>
                          <div class="grid grid-cols-12 gap-4">
                              <div class="intro-y col-span-12">
                                  <!-- BEGIN: Input -->
                                  <div class="">
                                      <div id="input" class="py-5">
                                          <div class="preview">
                                              <div>
                                                  <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div class="">
                                                              <div id="input-group-4">Kapan</div>

                                                              <input id="pos-form-1" v-model="reject_form.kapan" name="kapan" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div id="input-group-3" class="">Stone</div>
                                                          <select class="form-select" v-model="reject_form.stone_id" disabled aria-label="Default select example">
                                                              <option value="1">N</option>
                                                              <option value="2">CVD</option>
                                                              <option value="3">HPHT</option>
                                                          </select>
                                                          <!-- <template v-if="form_validate.stone_id.$error">
                                                              <div v-for="(error, index) in form_validate.stone_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template> -->
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3">Available Pieces</div>
                                                                  <input type="text" class="form-control" name="piece" disabled v-model="reject_form.avail_pcs" aria-describedby="input-group-3" />
                                                              </div>

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4">Available Weight (Ct)</div>
                                                              <input type="text" class="form-control" name="weight" disabled v-model="reject_form.avail_wt" aria-describedby="input-group-4" />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- <div class="mt-3" v-if="reject_form.purchase_type == 2">
                                                    <div id="input-group-3" class="">Rejection Type</div>
                                                    <select class="form-select w-full" v-model="reject_form.rejection_type" disabled aria-label="Default select example">
                                                        <option value="1">Direct Rejection</option>
                                                        <option value="2" v-if="reject_form.purchase_type == 2">Net Rejection</option>
                                                    </select>
                                              </div> -->
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3">Pieces</div>
                                                                  <input type="text" class="form-control" name="piece" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="reject_form.pieces" placeholder="No of Pieces" aria-describedby="input-group-3" />
                                                              </div>
                                                              <!-- <template v-if="reject_validate.pieces.$error">
                                                                  <div v-for="(error, index) in reject_validate.pieces.$errors" :key="index" class="text-theme-6 mt-2">
                                                                      {{ error.$message }}
                                                                  </div>
                                                              </template> -->

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4" :class="{ 'border-theme-6': reject_validate.weight.$error }">Weight (Ct) <span class="text-theme-6"> * </span></div>
                                                              <input type="text" class="form-control" name="weight" :class="{ 'border-theme-6': reject_validate.weight.$error }" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="reject_form.weight" placeholder="Weight" aria-describedby="input-group-4" />
                                                          </div>
                                                          <template v-if="reject_validate.weight.$error">
                                                              <div v-for="(error, index) in reject_validate.weight.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">Stone && Est Value</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="">

                                                          </div>
                                                      </div>
                                                      <div>
                                                          <div class="mt-2 sm:mt-0">
                                                              <div class="flex justify-end items-center mt-5">
                                                                  <button type="button" :disabled="page_data.r_loading == true" class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                  <button type="button" @click="process_reject" :disabled="page_data.r_loading == true" class="btn btn-primary w-24 m-auto"> Reject
                                                                      <LogInIcon v-if="page_data.r_loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                      <LoadingIcon v-if="page_data.r_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- END: Input -->
                              </div>
                          </div>
                          <!-- END: Data List -->
                      </div>
                      <!-- </div> -->
                  </div>
              </div>
          </div>
      </div>
      <!-- BEGIN: Single Process Assign Modal -->
      <!-- END: Data List -->
      <div id="add-packet-part-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body p-10">
                      <!-- <div id="vertical-form"> -->
                      <div class="flex flex-col justify-between mt-5">
                          <!-- BEGIN: Data List -->
                          <div class="intro-y overflow-auto">
                              <div class="flex items-center">
                                  <h2 class="intro-y text-lg font-medium mr-5">Add Sub Part</h2>
                              </div>
                          </div>
                          <div class="grid grid-cols-12 gap-4">
                              <div class="intro-y col-span-12">
                                  <!-- BEGIN: Input -->
                                  <div class="">
                                      <div id="input" class="py-5">
                                          <div class="preview">
                                              <div>
                                                  <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div class="">
                                                              <div id="input-group-4">Packet No</div>

                                                              <input id="pos-form-1" v-model="page_data.sub_part.packet_no" name="kapan" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                              <div id="input-group-4">Stock No</div>

                                                              <input id="pos-form-1" v-model="page_data.sub_part.vepari_no" name="stock" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3">Available Weight(Ct)</div>
                                                                  <input type="text" class="form-control" name="piece" disabled v-model="page_data.sub_part.weight" @input="$h.preventInvalidInput" aria-describedby="input-group-3" />
                                                              </div>

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4">New Part Weight (Ct)</div>
                                                              <input type="text" class="form-control" name="new weight" v-model="page_data.sub_part.new_weight" @input="$h.preventInvalidInput" aria-describedby="input-group-4" />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- <div class="mt-3" v-if="reject_form.purchase_type == 2">
                                                    <div id="input-group-3" class="">Rejection Type</div>
                                                    <select class="form-select w-full" v-model="reject_form.rejection_type" disabled aria-label="Default select example">
                                                        <option value="1">Direct Rejection</option>
                                                        <option value="2" v-if="reject_form.purchase_type == 2">Net Rejection</option>
                                                    </select>
                                              </div> -->
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">Stone && Est Value</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="">

                                                          </div>
                                                      </div>
                                                      <div>
                                                          <div class="mt-2 sm:mt-0">
                                                              <div class="flex justify-end items-center mt-5">
                                                                  <button type="button" :disabled="page_data.sub_part.loading == true" class="btn btn-secondary w-24 m-auto mr-3 btn-sm" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                  <button type="button" @click="add_sub_part" :disabled="page_data.sub_part.loading == true" class="btn btn-primary btn-sm w-28 m-auto"> Add Part
                                                                      <LogInIcon v-if="page_data.sub_part.loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                      <LoadingIcon v-if="page_data.sub_part.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- END: Input -->
                              </div>
                          </div>
                          <!-- END: Data List -->
                      </div>
                      <!-- </div> -->
                  </div>
              </div>
          </div>
      </div>
      <!-- BEGIN: Single Process Assign Modal -->

  </div>
  </template>

  <script>
  import {
      computed,
      defineComponent,
      inject,
      onMounted,
      reactive,
      ref,
      toRefs
  } from 'vue'
  import {
      mapGetters,
      mapActions
  } from 'vuex'

  import {
      BASE_URL
  } from '@/constants/config.js'
  import axios from 'axios'
  import datetime from 'vuejs-datetimepicker';
  import {
      helper as $h
  } from '@/utils/helper'
  import moment from 'moment';
  import {
      useVuelidate
  } from '@vuelidate/core'
  import {
      required,
      minLength,
      maxLength,
      email,
      url,
      integer
  } from '@vuelidate/validators'
  import cash from 'cash-dom'
  import {
      useRoute
  } from 'vue-router';
  import store from '@/store';

  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  // const router = useRouter();
  // const route = useRoute();

  export default defineComponent({
      components: {
          datetime
      },
      // data() {
      //     return {
      //         transaction_details: [],
      //         kapan_managers: [],
      //         user_details: this.$store.state.user.currentUser,
      //         transaction_packets: [],
      //         req: axios.create({
      //             baseUrl: BASE_URL
      //         }),
      //         page_data: {
      //             r_loading: false,
      //         },
      //         reject_form: {
      //             rejection_type: '1',
      //             pieces: '',
      //             weight: '',
      //             purchase_id: '',
      //             kapan: '',
      //             lot_no: '',
      //             stone_id: '',
      //             avail_pcs: '',
      //             avail_wt: '',
      //         },
      //         reject_rules: {
      //             pieces: {
      //                 required,
      //                 integer
      //             },
      //             weight: {
      //                 required,
      //             }
      //         },
      //         reject_validate: useVuelidate(this.reject_rules, toRefs(this.reject_form)),
      //         moment: moment
      //     }
      // },
      // mounted() {
      //     this.getRoughDetails(this.$route.params.id);
      // },

      // methods: {
      //     async getRoughDetails(id) {
      //         let _this = this;
      //         const params = {
      //             trans_id: id
      //         };
      //         let promise = axios({
      //             url: "api/kpn/sp",
      //             method: "POST",
      //             params: params,
      //             baseURL: BASE_URL,
      //             headers: {
      //                 Authorization: "Bearer " + localStorage.getItem("token")
      //             },
      //             config: {
      //                 headers: {
      //                     Accept: "application/json"
      //                 }
      //             }
      //         });
      //         return promise
      //             .then(result => result.data)
      //             .then(data => {
      //                 this.transaction_details = data.purchase;
      //                 this.kapan_managers = data.kapan_managers;
      //                 this.transaction_packets = this.transaction_details.history;

      //                 console.log(this.transaction_details)

      //             })
      //             .catch(_error => {

      //             });
      //     },
      //     async addRejection() {

      //         this.reject_form.purchase_id = this.transaction_details.id
      //         this.reject_form.kapan = this.transaction_details.voucher_no
      //         this.reject_form.lot_no = this.transaction_details.lot_no
      //         this.reject_form.avail_pcs = this.transaction_details.cr_pcs
      //         this.reject_form.avail_wt = this.transaction_details.cr_wt
      //         this.reject_form.stone_id = String(this.transaction_details.stone_type)

      //         cash('#rough-process-reject-modal').modal('show')
      //     },
      //     // Process Cancel
      //     async process_reject() {

      //         this.reject_validate.$touch()
      //         if (this.reject_validate.$invalid) {
      //             Swal.fire('Error', 'Fields are empty.', 'error');
      //             return
      //         }

      //         const params = {
      //             rejection_type: (this.reject_form.rejection_type != '' ? window.btoa(this.reject_form.rejection_type) : ''),
      //             pieces: (this.reject_form.pieces != '' ? window.btoa(this.reject_form.pieces) : ''),
      //             weight: (this.reject_form.weight != '' ? window.btoa(this.reject_form.weight) : ''),
      //             id: (this.reject_form.purchase_id != '' ? window.btoa(this.reject_form.purchase_id) : ''),
      //         }

      //         let promise_url = 'api/kpn/rrp'
      //         this.page_data.r_loading = true

      //         let promise = axios({
      //             url: promise_url,
      //             method: "POST",
      //             data: params,
      //             baseURL: BASE_URL,
      //             headers: {
      //                 Authorization: "Bearer " + localStorage.getItem("token")
      //             },
      //             config: {
      //                 headers: {
      //                     Accept: "application/json"
      //                 }
      //             }
      //         });
      //         return promise
      //             .then(result => result.data)
      //             .then(data => {

      //                 if (data.status == 'error') {
      //                     Swal.fire('Warning', data.message, 'warning');
      //                     this.page_data.r_loading = false
      //                 } else {
      //                     Swal.fire('Success', data.message, 'success');

      //                     this.reject_form.rejection_type = '1'
      //                     this.reject_form.pieces = ''
      //                     this.reject_form.weight = ''
      //                     this.reject_form.purchase_id = ''
      //                     this.reject_form.kapan = ''
      //                     this.reject_form.lot_no = ''
      //                     this.reject_form.stone_id = ''
      //                     this.reject_form.avail_pcs = ''
      //                     this.reject_form.avail_wt = ''

      //                     // this.issue_form.pieces = ''
      //                     // this.issue_form.weight = ''
      //                     // this.issue_form.purchase_id = ''
      //                     // this.issue_form.process_id = '0'
      //                     // this.issue_form.employee_id = '0'
      //                     // this.issue_form.external = '0'
      //                     this.page_data.r_loading = false

      //                     cash('#rough-process-reject-modal').modal('hide')
      //                     this.reject_validate.value.$reset()
      //                     getRoughDetails()
      //                 }
      //             })
      //             .catch(_error => {
      //                 this.page_data.r_loading = false
      //                 this.Swal('Error', 'Something went wrong.', 'error');
      //             });

      //     }

      // },
      setup() {

          const user_details = computed(() => store.state.user.currentUser)
          const route = useRoute()
          const transaction_details = ref([])
          const kapan_managers = ref([])
          const planning_stocks = ref([])
          const transaction_packets = ref([])
          const rejection_details = ref([])
          const total = ref([])
          const tableRef = ref()
          const packet = ref([])
          const tabulator = ref()
          const reject_form = reactive({
              rejection_type: '1',
              purchase_type: '1',
              pieces: '',
              weight: '',
              purchase_id: '',
              kapan: '',
              lot_no: '',
              stone_id: '',
              avail_pcs: '',
              avail_wt: '',
          })
          const swal = inject('$swal')

          const page_data = reactive({
              r_loading: false,
              sub_part: {
                packet_no: '',
                vepari_no: '',
                packet_id: '',
                weight: '',
                new_weight: '',
                loading: false
              },
              lot_items: [],
              compare_items: ['1', '2', '3', '4', '5'],
              compare_items_2: ['1', '2', '3', '4'],
              kapan_id: '0',

          })

          const reject_rules = {
              // pieces: {
              //     required,
              //     integer
              // },
              weight: {
                  required,
              }
          }

          const reject_validate = useVuelidate(reject_rules, toRefs(reject_form))

          const initTabulator = () => {
              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + 'api/kpn/rpc-gpl',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: {
                      kapan_id: (page_data.kapan_id != "0" ? window.btoa(page_data.kapan_id ): '')
                  },
                  ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          // pagination: 'remote',
          // paginationSize: 20,
          // paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: false,
          tooltipsHeader:true,
          headerSort: false,
                  dataTree:true,
                  dataTreeStartExpanded:true,
                  // pagination: 'remote',
                  // paginationSize: 20,
                  // paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: 'collapse',
                  placeholder: 'No matching records found',
                  columns: [{
                          formatter: 'responsiveCollapse',
                          width: 40,
                          minWidth: 30,
                          hozAlign: 'center',
                          resizable: false,
                          headerSort: false
                      },

                      // For HTML table
                      {
                          title: 'Packet No',
                          responsive: 0,

                          field: 'number',
                          vertAlign: 'middle',
                          minWidth: 150,
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                  }</div>
                </div>`
                          }
                      },
                      {
                          title: 'Stock No',
                          responsive: 0,
                          field: 'vepari_no',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().vepari_no
                  }</div>
                </div>`
                          }
                      },
                      {
                          title: 'Type',
                          responsive: 0,
                          field: 'type',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().type
                  }</div>
                </div>`
                          }
                      },
                      {
                          title: 'Rough Weight',
                          responsive: 0,
                          field: 'weight',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().weight
                  }</div>
                </div>`
                          }
                      },
                      {
                          title: 'Weight',
                          responsive: 0,
                          field: 'mk_weight',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().mk_weight ? cell.getData().mk_weight : cell.getData().cr_weight
                  }</div>
                </div>`
                          }
                      },
                      {
                          title: ' Cr Weight',
                          responsive: 0,
                          field: 'cr_weight',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().cr_weight
                  }</div>
                </div>`
                          }
                      },
                      {
                          title: 'Lose Wt (Ct)',
                          field: 'ghat_weight',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },
                      {
                          title: 'Rejection Wt (Ct)',
                          field: 'total_assortment',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },
                      {
                          title: 'Net Rejection Wt (Ct)',
                          field: 'total_net_assortment',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },
                      {
                          title: 'Status',
                          field: 'status',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {

                            let status_name = ''
                            let status_color = ''

                            if(cell.getData().status == 2) {

                            status_name = 'In Process'
                            status_color = 'text-primary-1'
                            } else if(cell.getData().status == 1) {

                              status_name = 'Active'
                              status_color = 'text-primary-8'
                            } else if(cell.getData().status == 0) {

                              status_name = 'Hold'
                              status_color = 'text-primary-6'
                            } else if(cell.getData().status == 3) {

                              status_name = 'Cancelled'
                              status_color = 'text-primary-3'
                            } else if(cell.getData().status == 4) {

                              status_name = 'Delivered'
                              status_color = 'text-primary-4'
                            } else if(cell.getData().status == 5) {

                              status_name = 'Blocked'
                              status_color = 'text-primary-4'
                            } else if(cell.getData().status == 6) {

                              status_name = 'Outward'
                              status_color = 'text-primary-4'
                            }
                              const a = cash(`<div class="w-full m-0">
                              <div class="whitespace-nowrap"><p class="${
                                status_color + '">' + status_name + '</p>'
                                // cell.getData().status == 2 ? '<p class="text-primary-1">In Process</p>': (cell.getData().status == 1 ? '<p class="text-primary-8">Active</p>' : (cell.getData().status == 0 ? '<p class="text-primary-6">Hold</p>' : (cell.getData().status == 3 ? '<p class="text-primary-3">Cancelled</p>' : '<p class="text-primary-4">Delivered</p>')))
                              }</div>
                            </div>`)
                              cash(a).on('click', function () {
                                  Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                              })
                              return a[0]
                          }
                      },
                      {
                          title: 'Print',
                          field: 'status',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {

                              const a = cash(`<button class="btn btn-sm btn-secondary m-0">
                              <div class="whitespace-nowrap"><p><i data-feather="printer" class="w-4 h-4"></i></p></div>
                            </button>`)
                              cash(a).on('click', function () {
                                  packet.value = cell.getData()
                                setTimeout(() => {

                                  document.getElementById('print-btn').click()
                                }, 100);

                                  console.warn(packet.value)
                              })
                              return a[0]
                          }
                      },
                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })

          }

          const getRoughDetails = async (id) => {
              const params = {
                  trans_id: id
              };
              let promise = axios({
                  url: "api/report/spr",
                  method: "POST",
                  params: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      console.log(data)
                      transaction_details.value = data.purchase;
                      kapan_managers.value = data.kapan_managers;
                      total.value = data.total;
                      rejection_details.value = data.rejection_details;
                      transaction_packets.value = transaction_details.value.history;

                      console.log(transaction_details.value)

                  })
                  .catch(_error => {

                  });
          }
          const addRejection = () => {

              reject_form.purchase_id = transaction_details.value.id
              reject_form.kapan = transaction_details.value.voucher_no
              reject_form.lot_no = transaction_details.value.lot_no
              reject_form.avail_pcs = transaction_details.value.cr_pcs
              reject_form.purchase_type = transaction_details.value.purchase_type
              reject_form.rejection_type = transaction_details.value.purchase_type == 1 ? '1' : '2'
              reject_form.avail_wt = transaction_details.value.cr_wt
              reject_form.stone_id = String(transaction_details.value.stone_type)

              cash('#rough-process-reject-modal').modal('show')
          }
          // Process Cancel
          const process_reject = async () => {

              reject_validate.value.$touch()
              if (reject_validate.value.$invalid) {
                  swal('Error', 'Fields are empty.', 'error');
                  return
              }

              const params = {
                  rejection_type: (reject_form.rejection_type != '' ? window.btoa(reject_form.rejection_type) : ''),
                  pieces: (reject_form.pieces != '' ? window.btoa(reject_form.pieces) : ''),
                  weight: (reject_form.weight != '' ? window.btoa(reject_form.weight) : ''),
                  id: (reject_form.purchase_id != '' ? window.btoa(reject_form.purchase_id) : ''),
              }

              let promise_url = 'api/kpn/rrp'
              page_data.r_loading = true

              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.status == 'error') {
                          swal('Warning', data.message, 'warning');
                          page_data.r_loading = false
                      } else {
                          swal('Success', data.message, 'success');

                          reject_form.rejection_type = '1'
                          reject_form.pieces = ''
                          reject_form.weight = ''
                          reject_form.purchase_id = ''
                          reject_form.kapan = ''
                          reject_form.lot_no = ''
                          reject_form.stone_id = ''
                          reject_form.avail_pcs = ''
                          reject_form.avail_wt = ''
                          page_data.r_loading = false

                          cash('#rough-process-reject-modal').modal('hide')
                          reject_validate.value.$reset()
                          getRoughDetails(route.params.id)
                          initTabulator()
                      }
                  })
                  .catch(_error => {
                      page_data.r_loading = false
                      swal('Error', 'Something went wrong.', 'error');
                  });

          }

          const add_sub_part = async () => {

            let cr_weight = page_data.sub_part.weight

            if(parseFloat(cr_weight) <= parseFloat(page_data.sub_part.new_weight)) {
              swal('Error', 'Not allowed to add a piece with invalid weight!.', 'error');
                  return
            }

              const params = {
                packet_no: window.btoa(page_data.sub_part.packet_no),
                vepari_no: window.btoa(page_data.sub_part.vepari_no),
                packet_id: window.btoa(page_data.sub_part.packet_id),
                weight: window.btoa(page_data.sub_part.weight),
                nw: window.btoa(page_data.sub_part.new_weight),
              }

              let promise_url = 'api/kpn/rpc-asp'
              page_data.sub_part.loading = true

              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.status == 'error') {
                          swal('Warning', data.message, 'warning');
                          page_data.sub_part.loading = false
                      } else {
                          swal('Success', data.message, 'success');

                          page_data.sub_part.packet_no = ''
                          page_data.sub_part.vepari_no = ''
                          page_data.sub_part.packet_id = ''
                          page_data.sub_part.weight = ''
                          page_data.sub_part.new_weight = ''
                          page_data.sub_part.loading = false

                          cash('#add-packet-part-modal').modal('hide')
                          getRoughDetails(route.params.id)
                          initTabulator()
                      }
                  })
                  .catch(_error => {
                    page_data.sub_part.loading = false
                      swal('Error', 'Something went wrong.', 'error');
                  });
          }

          const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    page_data.kapan_id = '0'
                    page_data.lot_items = []
                });
        }

          const getReportDetails = () => {

            const params = {
                trans_id: (page_data.kapan_id != "0" ? window.btoa(page_data.kapan_id) : '-')
            };

            initTabulator()
              let promise = axios({
                  url: "api/report/spr",
                  method: "POST",
                  params: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      console.log(data)
                      transaction_details.value = data.purchase;
                      kapan_managers.value = data.kapan_managers;
                      planning_stocks.value = data.planning_stocks;
                      rejection_details.value = data.rejection_details;
                      total.value = data.total;
                      transaction_packets.value = transaction_details.value.history;

                      console.log(transaction_details.value)

                  })
                  .catch(_error => {

                  });

          }

          onMounted(() => {

              // getRoughDetails(route.params.id)
              // initTabulator()
              getLotDetails()
          })
          return {
            packet,
              page_data,
              transaction_details,
              kapan_managers,
              transaction_packets,
              tableRef,
              tabulator,
              process_reject,
              user_details,
              reject_form,
              reject_rules,
              reject_validate,
              addRejection,
              rejection_details,
              initTabulator,
              add_sub_part,
              getReportDetails,
              planning_stocks,
              getLotDetails,
              total
          }
      }

  })
  </script>
