<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Master</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <div class="pos intro-y grid gap-5 my-8">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12 lg:col-span-8">
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in" v-for="(master,index) in masters" :key="index">
          <router-link class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in px-6" :to="master.to"
          >
              <div class="font-medium text-base text-center">{{ master.name }}</div>
          </router-link>
          </div>
        </div>
        <h2 class="text-lg font-medium mr-auto  border-t mt-5 pt-5 border-theme-5">Process & Department</h2>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in" v-for="(department,index) in departments" :key="index">
          <router-link class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in px-6" :to="department.to"
          >
            <div class="font-medium text-base text-center">{{ department.name }}</div>
          </router-link>
          </div>
        </div>
        <h2 class="text-lg font-medium mr-auto  border-t mt-5 pt-5 border-theme-5">Users</h2>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in" v-for="(user,index) in users" :key="index">
            <router-link class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in px-6" :to="user.to"
            >
              <div class="font-medium text-base text-center">{{ user.name }}</div>
            </router-link>
          </div>
        </div>
        <h2 class="text-lg font-medium mr-auto  border-t mt-5 pt-5 border-theme-5">Rates</h2>
        <div class="grid grid-cols-12 gap-5 mt-5">
          <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in" v-for="(rate,index) in Rates" :key="index">
            <router-link class="col-span-12 sm:col-span-4 2xl:col-span-3 box cursor-pointer zoom-in px-6" :to="rate.to"
            >
              <div class="font-medium text-base text-center">{{ rate.name }}</div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const masters = [
      {
        name: 'Color',
        to: { name: 'color' },
        access: 103
      },
      {
        name: 'Cut',
        to: { name: 'cut' },
        access: 104
      },
      {
        name: 'Purity',
        to: { name: 'purity' },
        access: 105
      },
      {
        name: 'Polish',
        to: { name: 'polish' },
        access: 106
      },
      {
        name: 'Shape',
        to: { name: 'shape' },
        access: 107
      },
      {
        name: 'Stone',
        to: { name: 'stone' },
        access: 108
      },
      {
        name: 'Symmetry',
        to: { name: 'symmetry' },
        access: 109
      },
      {
        name: 'Fluerocent',
        to: { name: 'fluerocent' },
        access: 110
      },
      {
        name: 'Tension',
        to: { name: 'tension' },
        access: 111
      },
      {
        name: 'Lab. Certificate',
        to: { name: 'lab-certificate' },
        access: 113
      },
      {
        name: 'Ratio',
        to: { name: 'ratio' },
        access: 114
      },
      {
        name: 'Tabl',
        to: { name: 'tabl' },
        access: 114
      },
      {
        name: 'Height',
        to: { name: 'height' },
        access: 114
      },
      {
        name: 'Back',
        to: { name: 'back' },
        access: 114
      },
      {
        name: 'Charni',
        to: { name: 'charni' },
        access: 114
      },
    ]
    const users = [
      {
        name: 'Party',
        to: { name: 'vendor-details' },
        access: 201
      },
      {
        name: 'Manager',
        to: { name: 'manager-details' },
        access: 202
      },
      {
        name: 'Employee',
        to: { name: 'employee-details' },
        access: 203
      },
      {
        name: 'Job Work',
        to: { name: 'jober-details' },
        access: 204
      },
      {
        name: 'Planner',
        to: { name: 'planner-details' },
        access: 240
      },
    ]
    const departments = [
      {
        name: 'Process',
        to: { name: 'process' },
        access: 205
      },
      {
        name: 'Department',
        to: { name: 'department' },
        access: 206
      },
      // {
      //   name: 'Process Transfer / Delete',
      //   to: { name: 'ProcessTransfer' },
      //   access: 207
      // }
    ]
    const Rates = [
      {
        name: 'Calculate Rates',
        to: { name: 'manual-rate-list' },
        access: 208
      },
      {
        name: 'Employee Rates',
        to: { name: 'employee-rates' },
        access: 208
      },
      {
        name: 'Manager Rates',
        to: { name: 'manager-rates-user' },
        access: 209
      },
      {
        name: 'Party Rates',
        to: { name: 'vendor-rates-user' },
        access: 210
      },
      {
        name: 'Jober Rates',
        to: { name: 'jober-rates-user' },
        access: 211
      },
      // ,
      // {
      //   name: 'Plan / Check / LSBS',
      //   to: { name: 'rough-rates' },
      //   access: 249
      // },
      {
        name: 'Rapo Rates',
        to: { name: 'repo-rates' },
        access: 247
      },
      {
        name: 'Majuri Alert',
        to: { name: 'costing-rates' },
        access: 114
      },
      {
        name: 'Margin / Costing',
        to: { name: 'margin-costing-rates' },
        access: 114
      },
    ]
    const accesses = computed(() => store.state.user.actions)
    return {
      masters,
      accesses,
      users,
      Rates,
      departments
    }
  }
})
</script>
