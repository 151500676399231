<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Makeable Outward Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-4 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
                >Packet / Lot / Stock No</label
              >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_no"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-4 xl:col-span-2" v-if="user_details.user_role != 3">
          <div>
              <label for="update-profile-form-6" class="form-label py-1"
                >Select Party</label
              >
              <TomSelect
                v-model="filter.party_id"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="0">Select Party</option>
              <option v-for="party in pagedata.parties" :key="party.id" :value="party.id">{{party.first_name}} {{ party.last_name }} ({{ party.short_name }})</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-4 xl:col-span-2">
        <div>
          <label for="update-profile-form-6" class="form-label py-1"
                >Select Start Date</label
              >
          <input
            type= "date"
            id="update-profile-form-13"
            class="form-control"
            placeholder=""
            v-model="filter.start_date"
          />
        </div>

        </div>
        <div class="col-span-6 md:col-span-4 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              v-model="filter.end_date"
            />
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-2">
          <div class="mt-7 flex pt-3">

            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="generateReport()"
            >
            Generate
            </button>
            <export-excel
                  class   = "btn btn-primary whitespace-nowrap btn-sm py-2 ml-2"
                  :data   = "pagedata.items"
                  :fields = "pagedata.json_fields"
                  worksheet = "Makeable Outward"
                  name    = "makeable_outward.xls">

                  Excel Export

              </export-excel>
          </div>
        </div>
      </div>

      <div class="overflow-x-auto scrollbar-hidden mt-3">
          <!-- BEgin: Process Report Start-->

        <div>

          <div>
            <div>
              <!-- <div class="overflow-auto"> -->
                <table class="mt-3 min-w-full border-2">
                  <thead>
                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">#</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Packet</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Party</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Oriznal Wt (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Polish Wt (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Final Wt (Ct)</th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.shape" :name="'shape'" @onChecked="onShapeCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.color" :name="'color'" @onChecked="onColorCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.purity" :name="'purity'" @onChecked="onPurityCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.cut" :name="'cut'" @onChecked="onCutCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.polish" :name="'polish'" @onChecked="onPolishCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.symmetry" :name="'symmetry'" @onChecked="onSymmetryCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.fluerocent" :name="'fluerocent'" @onChecked="onFluerocentCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.tension" :name="'tension'" @onChecked="onTensionCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"><MultipleSelect :options="pagedata.parameters.stone" :name="'stone'" @onChecked="onStoneCheck"></MultipleSelect></th>
                      <th class="py-2 text-xs lg:text-md border-r">Party Rate</th>
                      <th class="py-2 text-xs lg:text-md border-r">Total Bhav</th>
                      <th class="py-2 text-xs lg:text-md w-20 border-r">Outward Date</th>
                      <!-- <th class="py-2 text-xs lg:text-md">Status</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="pagedata.loading == false">
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.oriznal_weight ? $h.myFloat(pagedata.total.oriznal_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.polish_weight ? $h.myFloat(pagedata.total.polish_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.final_weight ? $h.myFloat(pagedata.total.final_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                    </tr>
                    <tr class="border-b hover:bg-gray-200 p-0" v-for="(single_item,ind) in pagedata.items" :key="ind">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind + 1 }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.number }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.vepari_no }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.party_short_name ? single_item.party_short_name : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.oriznal_weight ? $h.myFloat(single_item.oriznal_weight): '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.polish_weight ? $h.myFloat(single_item.polish_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.final_weight ? $h.myFloat(single_item.final_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.shape ? single_item.shape : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.color ? single_item.color : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.purity ? single_item.purity : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.cut ? single_item.cut : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.polish ? single_item.polish : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.symmetry ? single_item.symmetry : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.fluerocent ? single_item.fluerocent : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.tension ? single_item.tension : '0' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.stone ? single_item.stone : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.party_rate ? single_item.party_rate : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.job_done_value ? single_item.job_done_value : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_date ? single_item.issue_date : '-' }}</td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_date ? single_item.issue_date : '-' }}</td> -->
                    </tr>
                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_packets ? pagedata.total.total_packets : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.oriznal_weight ? $h.myFloat(pagedata.total.oriznal_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.polish_weight ? $h.myFloat(pagedata.total.polish_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.final_weight ? $h.myFloat(pagedata.total.final_weight) : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ pagedata.total.total_majuri ? $h.myFloat(pagedata.total.total_majuri)  : '-' }}</td>
                      <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                      <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                    </tr>
                  </tbody>

                </table>
                <div id="responsive-table" class="p-5" v-if="pagedata.loading">
                  <div class="preview">
                    <div class="overflow-x-auto">
                      <div
                        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                      >
                        <LoadingIcon icon="bars" class="w-8 h-8" />
                        <div class="text-center text-xs mt-2">Getting Latest Price</div>
                    </div>
                    </div>
                  </div>
                </div>
                <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Records Not Found!</div>
                  <!-- <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div> -->
                </div>
              </div>
              <!-- END: Failed Notification Content -->
              <!-- </div> -->
            </div>
          </div>
        </div>
        </div>
    </div>
      <!-- END: HTML Table Data -->

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch, computed, inject } from 'vue';
import MultipleSelect from '@/components/MultipleSelect/Main.vue'
import store from '@/store'
import Toastify from 'toastify-js'

export default defineComponent({
  components:{
    MultipleSelect
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      total : [],
      items : [],
      parties : [],
      loading : false,
      parameters: {
        color: [],
        cut: [],
        purity: [],
        polish: [],
        shape: [],
        symmetry: [],
        fluerocent: [],
        tension: [],
        stone: []
      },
      json_fields: {
        'Packet': "number",
        'Stock No': "vepari_no",
        'Party': "party_short_name",
        'Oriznal Wt (Ct)': "oriznal_weight",
        'Polish Wt (Ct)': "polish_weight",
        'Final Wt (Ct)': "final_weight",
        'Shape': "shape",
        'Color': "color",
        'Purity': "purity",
        'Cut': "cut",
        'Polish': "polish",
        'Symmetry': "symmetry",
        'Fluerocent': "fluerocent",
        'Tension': "tension",
        'Stone': "stone",
        'Party Rate': "party_rate",
        'Total Bhav': "job_done_value",
        'Outward Date': "issue_date",
      },
    })
    const swal = inject('$swal')
    const filter = reactive({
      party_id: '0',
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      packet_no : '',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
    })
    const api_filter = reactive({
      party_id: '0',
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      packet_no : '',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
    })
    function getReportDetails() {
      api_filter.packet_no = filter.packet_no
      api_filter.party_id = filter.party_id
      api_filter.start_date = filter.start_date
      api_filter.end_date = filter.end_date
      api_filter.color = filter.color
      api_filter.cut = filter.cut
      api_filter.purity = filter.purity
      api_filter.polish = filter.polish
      api_filter.shape = filter.shape
      api_filter.symmetry = filter.symmetry
      api_filter.fluerocent = filter.fluerocent
      api_filter.tension = filter.tension
      api_filter.stone = filter.stone
      getReportDetail()
    }
    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parameters.color = res.data.color
        pagedata.parameters.cut = res.data.cut
        pagedata.parameters.purity = res.data.purity
        pagedata.parameters.symmetry = res.data.symmetry
        pagedata.parameters.polish = res.data.polish
        pagedata.parameters.fluerocent = res.data.fluerocent
        pagedata.parameters.tension = res.data.tension
        pagedata.parameters.shape = res.data.shape
        pagedata.parameters.stone = res.data.stone
      })
    }
    function getReportDetail() {
      pagedata.loading = true

      let params = {
        packet_no : window.btoa(api_filter.packet_no),
        party_id : api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id),
        start_date : api_filter.start_date,
        end_date : api_filter.end_date,
        color : api_filter.color,
        cut : api_filter.cut,
        purity : api_filter.purity,
        polish : api_filter.polish,
        shape : api_filter.shape,
        symmetry : api_filter.symmetry,
        fluerocent : api_filter.fluerocent,
        tension : api_filter.tension,
        stone : api_filter.stone,
      }

      let promise = axios({
        url: 'api/report/get_makeable_outward_report_details',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.items = data.items
          pagedata.total = data.total

          if(pagedata.items.length == 0) {
            Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
          }
          pagedata.loading = false
        })
        .catch(_error => {
          pagedata.items = []
          pagedata.total = []
          pagedata.loading = false
        });
    }
    function getPartyDetails() {

        let params = {
          // department_id : window.btoa(filter.department_id)
        }
        let api_url = 'api/master/dp_parties'
        let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            pagedata.parties = data.items
          })
          .catch(_error => {
            pagedata.parties = []
          });
    }
    function onColorCheck(val) {
      filter.color = val;
      getReportDetails()
    }
    function onPurityCheck(val) {
      filter.purity = val;
      getReportDetails()
    }
    function onCutCheck(val) {
      filter.cut = val;
      getReportDetails()
    }
    function onPolishCheck(val) {
      filter.polish = val;
      getReportDetails()
    }
    function onShapeCheck(val) {
      filter.shape = val;
      getReportDetails()
    }
    function onSymmetryCheck(val) {
      filter.symmetry = val;
      getReportDetails()
    }
    function onStoneCheck(val) {
      filter.stone = val;
      getReportDetails()
    }
    function onFluerocentCheck(val) {
      filter.fluerocent = val;
      getReportDetails()
    }
    function onTensionCheck(val) {
      filter.tension = val;
      getReportDetails()
    }
    function onResetFilter() {
      filter.packet_no = ''
      filter.party_id = '0'
      filter.start_date= moment().startOf('month').format('YYYY-MM-DD')
      filter.end_date= moment().format('YYYY-MM-DD')
      filter.color = []
      filter.cut = []
      filter.purity = []
      filter.polish = []
      filter.shape = []
      filter.symmetry = []
      filter.fluerocent = []
      filter.tension = []
      filter.stone = []
    }
    function generateReport() {
      getReportDetails()
    }
    function getProcessChange() {
      if(user_details.value.user_role == 1) {
        getManagerDetails()
      } else {
        getEmployeeDetails()
      }
    }

    onMounted(() => {
      getPartyDetails()
      getDiamontData()
      generateReport()
    })
    return {
      pagedata,
      filter,
      api_filter,
      user_details,
      generateReport,
      onResetFilter,
      getProcessChange,
      onColorCheck,
      onPurityCheck,
      onCutCheck,
      onPolishCheck,
      onShapeCheck,
      onSymmetryCheck,
      onFluerocentCheck,
      onStoneCheck,
      onTensionCheck,
    }
  }
})
</script>
