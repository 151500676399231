<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
        <h2 class="text-lg font-medium mr-auto">Packet Receive</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-12 xl:col-span-4">

                            <div>
                                <label for="scan_lot_no" class="form-label">Scan Lot No</label>
                                <div class="input-group mt-2">

                                    <input id="scan_lot_no" type="text" tabindex="1" placeholder="Scan Barcode" v-model="screenData.scan_lot_no" :class="{'form-control':true,'loading':screenData.scanload}" autocomplete="off" @change="scanAndAddPack" />

                                    <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                                        <SearchIcon class="w-5 h-5 mr-2" /> Search
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
    <!-- END: HTML Table Data -->

    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Scanned Packet Details</h2>
                    <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <button class="btn btn-primary shadow-md mr-2" @click="issueLabSubmit" :disabled="screenData.return_progress">
                            Receive Packets
                            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress" />
                        </button>
                    </div>
                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">

                        <div class="col-span-4 xl:col-span-4">

                            <div>
                                <label for="update-profile-form-6" class="form-label font-bold">
                                    Total Scanned Pcs. :&nbsp;</label>
                                <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                            </div>

                        </div>
                        <div class="col-span-4 xl:col-span-4">
                            <div>
                                <label for="update-profile-form-6" class="form-label font-bold">
                                    Total Pcs Weight. :&nbsp;</label>
                                <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}} </label>
                            </div>
                        </div>
                        <div class="col-span-4 xl:col-span-4 text-right">
                            <div>
                                <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect" :disabled="screenData.delet_row">
                                    Remove Selected Row
                                </button>
                            </div>
                        </div>
                        <div class="col-span-12 ">
                            <ag-grid-vue class="ag-theme-alpine" style="height: 500px" :columnDefs="columnDefs.value" :rowData="rowData.value" :defaultColDef="defaultColDef" rowSelection="multiple" :rowMultiSelectWithClick="true" animateRows="true" @grid-ready="onGridReady" @selection-changed="onGridRowSelect" :overlayNoRowsTemplate="overlayNoRowsTemplate">
                            </ag-grid-vue>
                        </div>

                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>

    <div id="receive-items-modal" ref="receive-items-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Receive Details</h2>
          </div>
          <div class="modal-body">


            <table class="w-full border">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Packet No</th>
                  <th class="text-center">Stock No</th>
                  <th class="text-center">Process</th>
                  <th class="text-center">Issue Weight</th>
                  <th class="text-center">Receive Weight</th>
                  <th class="text-center">Rejection Weight</th>
                  <th class="text-center">Net Rejection Weight</th>
                  <th class="text-center">Shape</th>
                  <th class="text-center">Color</th>
                  <th class="text-center">Purity</th>
                  <th class="text-center">Cut</th>
                  <th class="text-center">Polish</th>
                  <th class="text-center">Symmetry</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Message</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border" v-for="(plan,index) in screenData.receive_data" :key="index">
                  <td class="text-center">
                    <p>{{ index + 1 }}</p>
                  </td>
                  <td class="text-center">{{ (plan.packet_no ? plan.packet_no : '-') }}</td>
                  <td class="text-center">{{ (plan.vepari_no ? plan.vepari_no : '-') }}</td>
                  <td class="text-center">{{ (plan.process ? plan.process : '-') }}</td>
                  <td class="text-center">{{ (plan.issue_weight ? plan.issue_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.receive_weight ? plan.receive_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.rejection_weight ? plan.rejection_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.net_rejection_weight ? plan.net_rejection_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.shape ? plan.shape : '-') }}</td>
                  <td class="text-center">{{ (plan.color ? plan.color : '-') }}</td>
                  <td class="text-center">{{ (plan.purity ? plan.purity : '-') }}</td>
                  <td class="text-center">{{ (plan.cut ? plan.cut : '-') }}</td>
                  <td class="text-center">{{ (plan.polish ? plan.polish : '-') }}</td>
                  <td class="text-center">{{ (plan.symmetry ? plan.symmetry : '-') }}</td>
                  <td class="text-center" :class="{'text-red' : plan.status_color == 'secondary','text-green' : plan.status_color == 'success'}">{{ (plan.status ? plan.status : '-') }}</td>
                  <td class="text-center" :class="{'text-red' : plan.status_color == 'secondary','text-green' : plan.status_color == 'success'}">{{ (plan.message ? plan.message : '-') }}</td>
                </tr>
              </tbody>

            </table>

          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import {
    AgGridVue
} from "ag-grid-vue3";
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import {
    stringify
} from 'json5';
import { helper as $h } from '@/utils/helper';

export default defineComponent({
    components: {
        AgGridVue,
        ShapeRenderer
    },
    setup() {
        const gridApi = ref(null);
        const router = useRouter()
        const route = useRoute()
        const swal = inject('$swal')
        const filter = reactive({
            field: 'lot_no',
            type: 'like',
            value: ''
        })

        const getRowId = (params) => {
            params.data.sr_no
            console.log(params.data.sr_no);
        }
        const screenData = reactive({
            scanload: false,
            color_arr: [],
            receive_data: [],
            purity_arr: [],
            shape_arr: [],
            polish_arr: [],
            symmetry_arr: [],
            fluerocent_arr: [],
            tension_arr: [],
            cut_arr: [],

            return_progress: false,
            scan_lot_no: '',
            lab_type: '',
            total_scan_pack_wt: 0,
            selected_party: '',
            total_scan_pack: 0

        })

        const onGridReady = (params) => {
            gridApi.value = params.api;
        };

        const rowData = reactive({
            value: []
        });

        const overlayNoRowsTemplate = 'Scan packets for Lab Issue!'

        const columnDefs = reactive({
            value: [{
                    headerName: "#",
                    field: 'sr_no',
                    maxWidth: 60,
                },
                {
                    headerName: "Packet No",
                    field: 'lot_no',
                    minWidth: 140
                },
                {
                    headerName: "Stock No",
                    field: 'stock_no',
                    minWidth: 140
                },
                {
                    headerName: "Issue Wt (Ct)",
                    field: 'issue_weight',
                    editable: false,
                    minWidth: 140,
                },
                {
                    headerName: "Receive Wt (Ct)",
                    field: 'receive_weight',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.color_name = ''
                            swal('Warning!', "Weight Can't be Empty!", "warning")
                            return false;
                          } else {
                            if($h.myFloat(params.newValue) > 0) {

                            } else {
                              swal('Warning!',"Invalid Receive Weight" +" Row!","warning")
                              return false;

                            }
                            console.log(params.data.cr_weight)
                            console.log(params.newValue)
                            if(parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
                              swal('Warning!',"Receive Weight Must be less then Issue Weight" +" Row!","warning")
                              return false;

                          }

                          let total_value = parseFloat(params.newValue) + parseFloat(params.data.assort_weight)

                            if(total_value > params.data.cr_weight) {

                              swal('Warning!',"Assort Weight Must be less then Issue Weight" +" Row!","warning")
                              return false;
                          }
                          params.data.receive_weight = params.newValue;

                            // swal('Warning!', "Invalid Color!", "warning")
                            setTimeout(focusScan, 50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Rejection Wt (Ct)",
                    field: 'assort_weight',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.color_name = ''
                            // swal('Warning!', "Weight Can't be Empty!", "warning")
                            return false;
                          } else {
                            if($h.myFloat(params.newValue) || params.newValue == 0) {

                            } else {
                              swal('Warning!',"Invalid Assort Weight" +" Row!","warning")
                              return false;

                            }
                            if(parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
                              swal('Warning!',"Assort Weight Must be less then Issue Weight" +" Row!","warning")
                              return false;

                            }

                            let total_value = parseFloat(params.newValue) + parseFloat(params.data.receive_weight)

                            if(total_value > params.data.cr_weight) {

                              swal('Warning!',"Assort Weight Must be less then Issue Weight" +" Row!","warning")
                              return false;
                          }
                          params.data.assort_weight = params.newValue;

                            // swal('Warning!', "Invalid Color!", "warning")
                            // setTimeout(focusScan, 50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Net Rejection Wt",
                    field: 'net_rejection_weight',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.color_name = ''
                            // swal('Warning!', "Weight Can't be Empty!", "warning")
                            return false;
                          } else {
                            if($h.myFloat(params.newValue) || params.newValue == 0) {

                            } else {
                              swal('Warning!',"Invalid Net Rejection Weight" +" Row!","warning")
                              return false;

                            }
                            if(parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
                              swal('Warning!',"Net Rejection Weight Must be less then Issue Weight" +" Row!","warning")
                              return false;

                            }

                            let total_value = parseFloat(params.newValue) + parseFloat(params.data.receive_weight)

                            if(total_value > params.data.cr_weight) {

                              swal('Warning!',"Net Rejection Weight Must be less then Issue Weight" +" Row!","warning")
                              return false;
                          }
                          params.data.net_rejection_weight = params.newValue;

                            // swal('Warning!', "Invalid Color!", "warning")
                            // setTimeout(focusScan, 50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Comment",
                    field: 'comment',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.color_name = ''
                            // swal('Warning!', "Weight Can't be Empty!", "warning")
                            return false;
                          } else {
                          //   if($h.myFloat(params.newValue) || params.newValue == 0) {

                          //   } else {
                          //     swal('Warning!',"Invalid Net Rejection Weight" +" Row!","warning")
                          //     return false;

                          //   }
                          //   if(parseFloat(params.newValue) > parseFloat(params.data.cr_weight)) {
                          //     swal('Warning!',"Net Rejection Weight Must be less then Issue Weight" +" Row!","warning")
                          //     return false;

                          //   }

                          //   let total_value = parseFloat(params.newValue) + parseFloat(params.data.receive_weight)

                          //   if(total_value > params.data.cr_weight) {

                          //     swal('Warning!',"Net Rejection Weight Must be less then Issue Weight" +" Row!","warning")
                          //     return false;
                          // }
                            params.data.comment = params.newValue;

                            // swal('Warning!', "Invalid Color!", "warning")
                            setTimeout(focusScan, 50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "4P",
                    field: "is_for_p",
                    minWidth: 100,
                    cellRenderer: function(params) {
                        var input = document.createElement('input');
                        input.type="checkbox";
                        input.id= params.node.data.lot_no + '_for_p';
                        input.checked=params.value;
                        input.addEventListener('click', function (event) {
                            params.value=!params.value;
                            params.node.data.is_for_p = params.value;

                            document.getElementById(params.node.data.lot_no + '_semi_polish').checked = false
                            params.node.data.is_semi_polish = false
                        });
                        return input;
                    }
                },
                {
                    headerName: "Semi Polish",
                    field: "is_semi_polish",
                    minWidth: 150,
                    cellRenderer: function(params) {
                        var input = document.createElement('input');
                        input.type="checkbox";
                        input.id= params.node.data.lot_no + '_semi_polish';
                        input.checked=params.value;
                        input.addEventListener('click', function (event) {
                            params.value=!params.value;
                            params.node.data.is_semi_polish = params.value;


                              document.getElementById(params.node.data.lot_no + '_for_p').checked = false
                              params.node.data.is_for_p = false
                        });
                        return input;
                    }
                },
                {
                    headerName: "Polish Wt (Ct)",
                    field: 'cr_polish_weight',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.color_name = ''
                            // swal('Warning!', "Weight Can't be Empty!", "warning")
                            return false;
                          } else {
                            if($h.myFloat(params.newValue) || params.newValue == 0) {

                            } else {
                              swal('Warning!',"Invalid Polish Weight" +" Row!","warning")
                              return false;

                            }
                            if(parseFloat(params.newValue) > parseFloat(params.data.receive_weight)) {
                              swal('Warning!',"Polish Weight Must be less then Receive Weight" +" Row!","warning")
                              return false;

                            }
                          }
                          params.data.cr_polish_weight = params.newValue;

                            // swal('Warning!', "Invalid Color!", "warning")
                            // setTimeout(focusScan, 50);
                            // return valueChanged;

                        }
                    },
                {
                    headerName: "Process",
                    field: 'process_name',
                    minWidth: 140
                },
                {
                    headerName: "Shape",
                    field: 'shape_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.shape_name = ''
                            swal('Warning!', "Shape Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.shape_arr.includes(params.newValue)) {

                                params.data.shape_name = params.newValue;
                                return;
                            } else if(params.data.shape_name == params.newValue) {

                            } else {

                              swal('Warning!', "Invalid Shape!", "warning")
                            }
                            setTimeout(focusScan, 50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Color",
                    field: 'color_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.color_name = ''
                            swal('Warning!', "Color Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.color_arr.includes(params.newValue)) {

                                params.data.color_name = params.newValue;
                                return;
                            } else if(params.data.color_name == params.newValue) {

                            } else {
                              swal('Warning!', "Invalid Color!", "warning")
                            }
                            // setTimeout(focusScan,50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Purity",
                    field: 'purity_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.purity_name = ''
                            swal('Warning!', "Purity Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.purity_arr.includes(params.newValue)) {

                                params.data.purity_name = params.newValue;
                                return;
                            }  else if(params.data.purity_name == params.newValue) {

                            } else {
                              swal('Warning!', "Invalid Purity!", "warning")
                            }
                            // setTimeout(focusScan,50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Cut",
                    field: 'cut_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.purity_name = ''
                            swal('Warning!', "Cut Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.cut_arr.includes(params.newValue)) {

                                params.data.cut_name = params.newValue;
                                return;
                            }  else if(params.data.cut_name == params.newValue) {

                            } else {
                              swal('Warning!', "Invalid Cut!", "warning")
                            }
                            // setTimeout(focusScan,50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Polish",
                    field: 'polish_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.purity_name = ''
                            swal('Warning!', "Polish Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.polish_arr.includes(params.newValue)) {

                                params.data.polish_name = params.newValue;
                                return;
                            }  else if(params.data.polish_name == params.newValue) {

                            } else {
                              swal('Warning!', "Invalid Polish!", "warning")
                            }
                            // setTimeout(focusScan,50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Symmetry",
                    field: 'symmetry_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.purity_name = ''
                            swal('Warning!', "Symmetry Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.symmetry_arr.includes(params.newValue)) {

                                params.data.symmetry_name = params.newValue;
                                return;
                            }  else if(params.data.symmetry_name == params.newValue) {

                            } else {
                              swal('Warning!', "Invalid Symmetry!", "warning")
                            }
                            // setTimeout(focusScan,50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Fluorescent",
                    field: 'fluerocent_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.purity_name = ''
                            swal('Warning!', "Fluorescent Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.fluerocent_arr.includes(params.newValue)) {

                                params.data.fluerocent_name = params.newValue;
                                return;
                            }
                              else if(params.data.fluerocent_name == params.newValue) {

                            } else {
                              swal('Warning!', "Invalid Fluorescent!", "warning")
                            }
                            // setTimeout(focusScan,50);
                            // return valueChanged;

                        }
                    },
                },
                {
                    headerName: "Tension",
                    field: 'tension_name',
                    editable: true,
                    minWidth: 140,
                    valueSetter: (params) => {
                        if (params.newValue == '') {
                            // params.data.purity_name = ''
                            swal('Warning!', "Tension Can't be Empty!", "warning")
                            return false;
                        } else {

                            if (screenData.tension_arr.includes(params.newValue)) {

                                params.data.tension_name = params.newValue;
                                return;
                            }
                              else if(params.data.tension_name == params.newValue) {

                            } else {
                              swal('Warning!', "Invalid Tension!", "warning")
                            }
                            // setTimeout(focusScan,50);
                            // return valueChanged;

                        }
                    },
                },
            ],
        });

        const defaultColDef = {
            sortable: true,
            filter: true,
            flex: 1
        };
        const get_all_details = () => {
            let params = {

            }
            let promise = axios({
                url: "/api/master/get_all_parameters",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    data.color.forEach(element => {
                        screenData.color_arr.push(element.color_name)
                    });
                    data.cut.forEach(element => {
                        screenData.cut_arr.push(element.cut_name)
                    });
                    data.purity.forEach(element => {
                        screenData.purity_arr.push(element.purity_name)
                    });
                    data.polish.forEach(element => {
                        screenData.polish_arr.push(element.polish_name)
                    });
                    data.shape.forEach(element => {
                        screenData.shape_arr.push(element.shape_name)
                    });
                    data.symmetry.forEach(element => {
                        screenData.symmetry_arr.push(element.symmetry_name)
                    });
                    data.fluerocent.forEach(element => {
                        screenData.fluerocent_arr.push(element.shape_name)
                    });
                    data.tension.forEach(element => {
                        screenData.tension_arr.push(element.tension_name)
                    });

                    // screenData.shape_arr = data.items

                })
                .catch(_error => {
                    screenData.color_arr = []
                    screenData.purity_arr = []
                    screenData.shape_arr = []
                    screenData.polish_arr = []
                    screenData.symmetry_arr = []
                    screenData.fluerocent_arr = []
                    screenData.tension_arr = []
                    screenData.cut_arr = []

                });
        }

        //Edit user
        const scanAndAddPack = () => {
            let codelength = screenData.scan_lot_no.trim().length;
            if (codelength > 5) {
                screenData.scanload = true;
                //Check already in array
                var i = rowData.value.length;
                while (i--) {
                    if (rowData.value[i]['barcode'] == screenData.scan_lot_no || rowData.value[i]['lot_no'] == screenData.scan_lot_no) {
                        screenData.scan_lot_no = "";
                        swal("Warning!", "Already in list, Scan other packet!", "warning");
                        screenData.scanload = false;
                        return;
                    }
                }

                //get lot details
                const data = {
                    barcode: screenData.scan_lot_no,
                };
                let url_post = '';
                if (screenData.scan_lot_no != '') {
                    url_post = "/api/full-process/get_lab_scanned_packet_details_party"
                } else {
                    url_post = "/api/full-process/get_lab_scanned_packet_details_party"
                }
                let promise = axios({
                    url: url_post,
                    method: "post",
                    data: data,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    },
                });
                return promise
                    .then((result) => result.data)
                    .then((data) => {
                        console.log(data);
                        if (data.packet_details == 'No') {
                            swal("Warning!", data.error_msg, "warning");
                            screenData.scanload = false;
                            document.querySelector('[id="scan_lot_no"]').focus();
                        } else if (data.packet_details == 'process') {
                            swal("Warning!", "Scanned barcode is already in process", "warning");
                            screenData.scanload = false;
                            document.querySelector('[id="scan_lot_no"]').focus();
                        } else {
                            let pack_details = data.packet_details;

                            // if(screenData.lab_type == '') {

                            //   screenData.lab_type = pack_details.lab
                            // }
                            // console.log('Hii data');
                            // let party_length = screenData.party_ar.length

                            // for(let i = 0; i< party_length; i++) {
                            //   if(screenData.party_ar[i].first_name == screenData.lab_type) {

                            //     screenData.selected_party = stringify(screenData.party_ar[i].id)
                            //     console.log(screenData.selected_party)
                            //     break;
                            //   }
                            // }
                            screenData.total_scan_pack = screenData.total_scan_pack + 1;
                            console.log('Push data');
                            rowData.value.push({
                                "sr_no": screenData.total_scan_pack,
                                "lot_no": pack_details.number,
                                "stock_no": pack_details.vepari_no,
                                "cr_weight": pack_details.cr_weight,
                                "cr_polish_weight": pack_details.cr_polish_weight,
                                "shape_name": pack_details.shape_name,
                                "color_name": pack_details.color_name,
                                "purity_name": pack_details.purity_name,
                                "cut_name": pack_details.cut_name,
                                "polish_name": pack_details.polish_name,
                                "purity_name": pack_details.purity_name,
                                "symmetry_name": pack_details.symmetry_name,
                                "fluerocent_name": pack_details.fluerocent_name,
                                "tension_name": pack_details.tension_name,
                                "barcode": pack_details.barnum,
                                "packet_id": pack_details.id,
                                "process_name": pack_details.process_name,
                                "process_code": pack_details.process_code,
                                "process_short_code": pack_details.process_short_code,
                                "issue_weight": pack_details.issue_weight,
                                "receive_weight": (pack_details.receive_weight ? pack_details.receive_weight : ''),
                                "assort_weight": (pack_details.assort_weight ? pack_details.assort_weight : ''),
                                "net_rejection_weight": (pack_details.net_rejection_weight ? pack_details.net_rejection_weight : ''),
                                "comment": '',
                                "is_for_p": false,
                                "is_semi_polish": false,
                                "process_id": pack_details.process_id,
                            });

                            screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.issue_weight);

                            gridApi.value.setRowData(rowData.value);
                            screenData.scan_lot_no = "";
                            screenData.scanload = false;
                            if (rowData.value.length == 1) {
                                setTimeout(focusLstRow, 50);
                            } else {
                                focusLstRow();
                            }
                            console.log(rowData.value);
                        }
                    })
                    .catch((_error) => {
                        screenData.scanload = false;
                        return [];
                    });
            }
        }

  //       const focusLstRow = () => {
  //         gridApi.value.ensureIndexVisible(rowData.value.length - 1);

  //         if(rowData.value[rowData.value.length - 1]['receive_weight'] == '' || rowData.value[rowData.value.length - 1]['receive_weight'] == null) {

  //           gridApi.value.setFocusedCell(rowData.value.length - 1,'receive_weight');
  //         } else {
  //           setTimeout(focusScan,100);
  //         }
  //         }
  //         gridApi.value.startEditingCell(
  //           {
  //             rowIndex: rowData.value.length - 1,
  //             colKey: 'receive_weight',
  //           }
  // );
  //       }

        const focusLstRow = () => {
            gridApi.value.ensureIndexVisible(rowData.value.length - 1);
            gridApi.value.setFocusedCell(rowData.value.length - 1,'receive_weight');
            gridApi.value.startEditingCell(
            {
              rowIndex: rowData.value.length - 1,
              colKey: 'receive_weight',
            }
            );
          }

        const onGridRowSelect = () => {
            console.log(gridApi.value.getSelectedRows().length);
            if (gridApi.value.getSelectedRows().length == 0) {
                screenData.delet_row = true;
            } else {
                screenData.delet_row = false;
            }
        }

        const issueLabSubmit = () => {

            if (rowData.value.length == 0) {
                swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
                return;
            }


            const data = {
                packet_details: rowData.value
            };

            console.log(data);

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You are about to Receive packets!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Receive",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let promise = axios({
                        url: "api/full-process/receive_full_process_rough_packets",
                        method: "post",
                        data: data,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.status == "success") {
                                // swal("Success!", "Item Received.", "success");
                                //screenData.return_progress = false;
                                // router.go();
                                screenData.receive_data = data.data
                                cash('#receive-items-modal').modal('show')
                                rowData.value = []
                                screenData.lab_type = ''
                                screenData.total_scan_pack_wt = 0
                                screenData.total_scan_pack = 0

                              } else if (data.status == "error") {
                                screenData.receive_data = data.data
                                cash('#receive-items-modal').modal('show')
                                // rowData.value = []
                                screenData.lab_type = ''
                                screenData.total_scan_pack_wt = 0
                                screenData.total_scan_pack = 0
                                // swal("Error!", data.message, "error");
                                // screenData.receive_data = []
                                screenData.return_progress = false;
                                return;
                            }
                        })
                        .catch(_error => {
                            screenData.return_progress = false;
                            return [];
                        });
                },
                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.value) {
                    screenData.return_progress = false;

                }
            });
        }

        const removeGridRowSelect = () => {
            console.log(gridApi.value.getSelectedRows());
            const sel = gridApi.value.getSelectedRows();
            console.log(sel);

            let remove_rows = [];
            var i = sel.length;
            while (i--) {
                remove_rows.push(sel[i]['barcode']);
            }
            gridApi.value.applyTransaction({
                remove: sel
            });
            console.log('Removeing row');
            console.log(remove_rows);
            var j = rowData.value.length;
            while (j--) {
                if (remove_rows.includes(rowData.value[j]['barcode'])) {
                    rowData.value.splice(j, 1);
                }
            }

            setTimeout(calculateTotal(), 50);
        }

        function calculateTotal () {
          screenData.total_scan_pack = rowData.value.length
          screenData.total_scan_pack_wt = 0;

          rowData.value.forEach(element => {
            screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(element.issue_weight);
          });
        }

        const focusScan = () => {

            document.querySelector('[id="scan_lot_no"]').focus();
        }

        onMounted(() => {

            document.querySelector('[id="scan_lot_no"]').focus();
            rowData.value = []
            get_all_details()
        })

        return {
            filter,
            screenData,
            columnDefs,
            rowData,
            defaultColDef,
            overlayNoRowsTemplate,
            scanAndAddPack,
            onGridReady,
            issueLabSubmit,
            getRowId,
            removeGridRowSelect,
            onGridRowSelect,
            deselectRows: () => {
                gridApi.value.deselectAll()
            }
        }

    }
})
</script>
