<template>
  <div>
      <div class="intro-y flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Add LS Majuri</h2>
      </div>
      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 xl:col-span-4">

                              <div>
                                  <label for="update-profile-form-8" class="form-label">Scan Lot No</label>
                                  <div class="input-group mt-2">

                                      <input id="update-profile-form-6" type="text" tabindex="1" placeholder="Scan Barcode" v-model="scan_lot_no" :class="{'form-control':true,'loading':scanload}" :disabled="scanload" autocomplete="off" @change="scanAndAddPack" />

                                      <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                                          <SearchIcon class="w-5 h-5 mr-2" /> Search
                                      </button>
                                      <button type="button" class="btn btn-primary-outline mr-auto" @click="clearSearch('')" style="border-radius: 0!important">
                                          Clear
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>

      <div class="grid grid-cols-12 mt-5" v-if="generate == true">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Process Details</h2>
                      <div>
                        <button class="btn btn-primary p-2" @click="submitReturnRough()">Update Weight</button>
                      </div>
                  </div>

                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 md:col-span-4">

                              <div>
                                  <label for="update-profile-form-6" class="form-label">Packet No :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.number}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue Lot weight :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.issue_weight}} Ct</label>
                              </div>

                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue To :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.employee_first_name + ' '+ packet_item.employee_last_name}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue At :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.created_at}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue Weight :&nbsp;</label>
                                  <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="packet_item.issue_weight" :class="{'form-control':true,'loading':scanload}" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateGhat" readonly />

                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Return Weight :&nbsp;</label>
                                  <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="packet_item.receive_weight" :class="{'form-control':true,'loading':scanload}" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateGhat" readonly />

                              </div>

                              <div class="mt-2">

                                  <label for="update-profile-form-6" class="form-label">Process Ghat :&nbsp;</label>
                                  <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="packet_item.ghat_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                              </div>
                              <div class="mt-2">

                                  <label for="update-profile-form-6" class="form-label">Process Assortment :&nbsp;</label>
                                  <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="packet_item.assortment_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                              </div>
                          </div>
                          <div class="col-span-12 md:col-span-2"></div>
                          <div class="col-span-12 md:col-span-4">
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Majuri Details </label>
                              </div>
                              <form class="w-full">
                                  <thead>
                                      <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Weight (Ct)</th>
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Majuri</th>
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="(item,index) in majuri_details" :key="index">
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control text-center" :disabled="index == 0" @change="getMajuri_details(item)" v-model="item.weight"></th>
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" disabled class="form-control text-center" v-model="item.majuri"></th>
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">
                                              <MinusSquareIcon class="w-8 h-8 text-primary-3" @click="removenewMajuriWeight(item)" />
                                          </th>
                                      </tr>
                                      <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat(majuri_total.weight) }}</th>
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat(majuri_total.majuri) }}</th>
                                          <th class="py-2 border-b text-xs lg:text-md text-center border-r"></th>
                                      </tr>
                                  </tbody>
                              </form>
                              <div class="mt-3">
                                  <button @click="addnewMajuriWeight(item)" class="btn btn-primary">Add Weight</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->

          </div>

      </div>
  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      defineComponent
  } from 'vue'
  import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import axios from 'axios'
  import datetime from 'vuejs-datetimepicker';
  import Tabulator from 'tabulator-tables'
  // import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
  import VueBarcode from '@chenfengyuan/vue-barcode';
import { swal } from 'sweetalert2/dist/sweetalert2';

  export default defineComponent({
      components: {
          DarkModeSwitcher,
          datetime,
          VueBarcode
      },
      props: {
          rough_id: {
              type: String,
              default: ""
          }
      },

      data() {
          return {
            packet_item: [],
            user_details: [],
            costing_value: 0,
            costing_value_manager: 0,
            majuri_total_id: 1,
            majuri_details: [{
                id: 1,
                weight: '',
                majuri: '',
              }],
              majuri_total: {
                weight: 0,
                majuri: 0,
              },
              scan_lot_no: '',
              scanload: false,
              generate: false
          }
      },
      computed: {

      },
      mounted() {

          this.user_details = this.$store.state.user.currentUser
      },

      methods: {
          async scanAndAddPack() {
              let _this = this;
              _this.clearSearch(this.scan_lot_no);
              this.scanload = true;
              this.generate = false;

              //get lot details
              const data = {
                  barcode: this.scan_lot_no,
              };
              let url_post = '';
              if (this.scan_lot_no != '') {
                  url_post = "api/process/scan_nd_add_lot_stock_ls"
              } else {
                  url_post = "api/process/scan_nd_add_lot_stock_ls"
              }
              let promise = axios({
                  url: url_post,
                  method: "post",
                  data: data,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
              });
              return promise
                  .then((result) => result.data)
                  .then((data) => {

                    this.scanload = false;

                    if(data.packet_details == "NO") {
                      swal("Error", data.error_msg, "error")
                      return;
                    }
                    this.generate = true;
                    this.packet_item = data.packet_details

                    if(data.packet_details.return_record != null) {
                      this.majuri_details = data.packet_details.return_record

                      this.majuri_total_id = this.majuri_details[this.majuri_details.length - 1].id

                      this.calculateMajuri()
                    } else {

                      this.majuri_details[0]['weight'] = this.packet_item.issue_weight

                      this.getMajuri_details(this.majuri_details[0])
                    }


                  })
                  .catch((_error) => {
                    // this.generate = true;
                      this.scanload = false;
                      return [];
                  });
          },
          clearSearch(lot_no) {
              this.scan_lot_no = lot_no;
              this.packet_item = [];
              this.majuri_total_id = 1
              this.majuri_details = [{
                  id: 1,
                  weight: '',
                  majuri: '',
              }]
              this.majuri_total = {
                  weight: 0,
                  majuri: 0,
              }
          },
          addnewMajuriWeight() {
              this.majuri_total_id = this.majuri_total_id + 1
              this.majuri_details.push({
                  id: this.majuri_total_id,
                  weight: '',
                  majuri: '',
              })
          },
          calculateMajuri() {
              let length = this.majuri_details.length

              this.majuri_total.weight = 0
              this.majuri_total.majuri = 0

              for (let i = 0; i < length; i++) {

                  console.log(this.majuri_details[i])

                  this.majuri_total.weight = parseFloat(parseFloat(this.majuri_total.weight) + parseFloat(this.majuri_details[i]['weight'] ? this.majuri_details[i]['weight'] : 0)).toFixed(4)
                  this.majuri_total.majuri = parseFloat(parseFloat(this.majuri_total.majuri) + parseFloat(this.majuri_details[i]['majuri'] ? this.majuri_details[i]['majuri'] : 0)).toFixed(2)
              }

          },
          removenewMajuriWeight(item) {

              this.$swal({
                  title: "Are you sure?",
                  text: "You are about cancel Weight!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Delete",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {
                      var i = this.majuri_details.length;
                      while (i--) {
                          if (this.majuri_details[i]['id'] == item.id) {
                              this.majuri_details.splice(i, 1);
                          }
                      }
                  }
                  this.getsubmitdetails()
                  this.calculateMajuri()
              });
          },
          submitReturnRough() {
              let submit = true

              if (submit == true) {

                  this.$swal({
                      title: "Are you sure?",
                      text: "You are You Want To Update LS Weight!",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancel",

                      allowOutsideClick: () => !$Swal.isLoading()
                  }).then(result => {
                      if (result.isConfirmed) {

                          let params = {
                              packet_id: window.btoa(this.packet_item.id),
                              trans_id: this.packet_item.transaction_id,
                              majuri_details: this.majuri_details,
                              majuri_total: this.majuri_total,
                          }

                          let api_url = 'api/process/ls_update_carats'

                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token")
                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                              .then((result) => result.data)
                              .then((data) => {
                                  if (data.status == 'success') {
                                      this.$swal("Success!", data.message, "success");
                                      this.scanAndAddPack()
                                  } else {

                                      this.$swal("Warning!", data.message, "warning");
                                  }
                              })
                      }
                  });

              }
          },
          getMajuri_details(item) {

            console.log(item)
              this.getsubmitdetails()


              this.calculateMajuri()
              let params = {
                  weight: item.weight,
                  packet_id: this.packet_item.id
              }
              axios({
                  url: 'api/process/get_ls_majuri_details',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {

                  let rate = res.data.rate

                  if (rate) {

                      var i = this.majuri_details.length;
                      while (i--) {
                          if (this.majuri_details[i]['id'] == item.id) {
                              this.majuri_details[i]['majuri'] = parseFloat(rate * this.majuri_details[i]['weight']).toFixed(2)
                          }
                      }
                      this.calculateMajuri()
                  }
              })
          },
          getsubmitdetails() {

              var length = this.majuri_details.length;

              for (let i = 0; i < length; i++) {
                  if (i != 0) {
                      // if (parseFloat(this.majuri_details[i]['weight']) > parseFloat(this.majuri_details[i - 1]['weight'])) {
                      //     this.$swal("Warning!", "Majuri Weight is Not Correct!", "warning");
                      //     this.majuri_details[i]['weight'] = ''
                      //     this.majuri_details[i]['majuri'] = ''
                      // }
                  }
              }
          },
      }

  })
  </script>
